import react from 'react';

export default function TermsAndConditions(props) {

    return(
        <div id="termandconditionspage" style={{ paddingTop: 50, paddingBottom: 50}}>

            <div className="container">
                <div className="row">
                    <div className="col s12">

                        <h2 className="center mb-3">TERMS & CONDITIONS</h2>

                        <p>
                            These Terms & Conditions (‘Terms’) apply to all Subscribers on this website. We may modify and update these Terms at any time, without notice. You need to ensure you review the Terms from time to time. In using our website and services, you agree to be bound by these Terms as well as any and all general Terms and Conditions posted on our website from time to time. If you do not accept these Terms, you cannot subscribe and advertise on the website.

                            <br/>
                            <br/>

                            These Terms may be amended at any time. Notification of the amended terms may be sent to you by email or as a general notice posted to our website. The amended Terms will then take effect immediately. If you do not agree with the amendments, then you must advise us that you wish to unsubscribe from advertising on the Website in accordance with our Cancellation and Termination policy. Your continued use of the Website is subject to our current Terms as amended from time to time.

                            <br/>
                            <br/>

                            If you register for a free listing on the website, or a paid subscription on the website, the applicable provisions of this agreement will also govern that.
                            <br/>  <br/>

                            By accepting these Terms, either by clicking a box indicating your acceptance or by executing an order form or subscription payment authorisation that references this agreement, you agree to these Terms of Use. If you are entering into this agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these Terms of Use, in which case the terms “You” or “Your” shall refer to such entity and its affiliates. If you do not have such authority, or if you do not agree with these terms and conditions, you must not accept this agreement and may not advertise with the website.

                            <br/>  <br/>

                            DEFINITIONS

                            <br/>  <br/>

                            “Subscriber” means a member subscriber who has been verified to our satisfaction, established an account and offers their Services through the website.

                            <br/>  <br/>

                            “Mini-site” is the page we set up on the website for all Paid Subscribers.

                            <br/>  <br/>

                            “Content” means any and all material, links, words, images including but not limited to any goods and services the Subscriber submits, advertises or links to the website.

                            <br/>  <br/>

                            “Customers” means any clients you obtain through advertising your professional services on the website.

                            <br/>  <br/>

                            “Services” the services you provide to customers in any of the service categories.

                            <br/>  <br/>

                            “Service Category” means on of the following categories of services:

                            <br/>  <br/>

                            Accommodation<br/>

                            Accountants<br/>

                            Acupuncture<br/>

                            Aged Care Services<br/>

                            Airconditioning - Automotive<br/>

                            Airconditioning - Commercial<br/>

                            Aircondotioning - Residential<br/>

                            Airport Shuttle Services<br/>

                            Alternative & Natural Therapies<br/>

                            Amusement Centres<br/>

                            Animals & Pet Services<br/>

                            Antennas<br/>

                            Aquariums & Supplies<br/>

                            Architects<br/>

                            Art Galleries<br/>

                            Art Schools<br/>

                            Asbestos Removal<br/>

                            Auctioneers<br/>

                            Audiovisual Equipment & Productions<br/>

                            Auto Electrical Services<br/>

                            Awnings<br/>

                            Baby Products<br/>

                            Baby Sitters<br/>

                            Bakery<br/>

                            Balustrading<br/>

                            Banking & Financial Services<br/>

                            Barbecues & Equipment<br/>

                            Bathroom Renovations<br/>

                            Barbers<br/>

                            Barristers<br/>

                            Bathroom Renovations<br/>

                            Batteries Automotive<br/>

                            Beauty Salons<br/>

                            Beds & Bedding<br/>

                            Bicycles - Accessories & Repairs<br/>

                            Blinds<br/>

                            Boat Charter Services<br/>

                            Boat Hire<br/>

                            Boat & Jetski Sales<br/>

                            Bookkeeping Services<br/>

                            Books - Retail<br/>

                            Boutiques<br/>

                            Bowling Clubs<br/>

                            Brakes & Clutch Services

                            Brick Cleaning<br/>

                            Bricklayers<br/>

                            Bridal Wear - Hire<br/>

                            Bridal Wear - Retail<br/>

                            Butchers<br/>

                            Building & Construction<br/>

                            Cabinet Makers<br/>

                            Cafes<br/>

                            Cakes<br/>

                            Cake Decoration Supplies<br/>

                            Cars - New<br/>

                            Cars - Used<br/>

                            Car Detailing<br/>

                            Car Hire<br/>

                            Car Rental<br/>

                            Car Wash Cafe<br/>

                            Car Parking<br/>

                            Caravan - New<br/>

                            Caravan - Used<br/>

                            Caravan - Hire<br/>

                            Carpenters<br/>

                            Carpet & Flooring<br/>

                            Carpet Cleaning<br/>

                            Carports & Pergolas<br/>

                            Cartridges - Ink & Toner<br/>

                            Catering & Food<br/>

                            Celebrant Services<br/>

                            Childcare Centres<br/>

                            Children Parties<br/>

                            Chiropractors<br/>

                            Churches, Mosques & Temples<br/>

                            Cinemas<br/>

                            City Councils<br/>

                            Cleaning Contractors<br/>

                            Cleaning - Homes<br/>

                            Cleaning Products & Supplies<br/>

                            Clothing<br/>

                            Clothing Alterations<br/>

                            Clubs & Pubs<br/>

                            Computer Equipment & Repairs<br/>

                            Concrete Contractors<br/>

                            Concrete Sawing<br/>

                            Councils<br/>

                            Conveyancing Services<br/>

                            Coolrooms<br/>

                            Cosmetic Surgery<br/>

                            Councilling Services<br/>

                            Courier Services<br/>

                            Crafts Retail<br/>

                            Crane Hire<br/>

                            Custom brokers<br/>

                            Dance Studio<br/>

                            Debt Collection Service<br/>

                            Delicatessens<br/>

                            Dentist<br/>

                            Disability Services<br/>

                            DJ mobile<br/>

                            Doctors - General Practice<br/>

                            Dog & Cat Grooming<br/>

                            Dog Training<br/>

                            Door and Gate Equipment<br/>

                            Drafting Service<br/>

                            Drainers<br/>

                            Driving School<br/>

                            Dry Cleaners<br/>

                            Education & Learning<br/>

                            Electrical Appliances<br/>

                            Electrical Appliances Repairs<br/>

                            Electricians<br/>

                            Embroidery Services<br/>

                            Emergency Services<br/>

                            Employment Services<br/>

                            Energy<br/>

                            Engineers<br/>

                            Engraving Services<br/>

                            Entertainment Promoters<br/>

                            Event Management<br/>

                            Events & Festivals<br/>

                            Excavating & Earth Moving Contractors<br/>

                            Fashion - Kids<br/>

                            Fashion - Mens<br/>

                            Fashion - Womens<br/>

                            Fencing Contractor<br/>

                            Finance Brokers<br/>

                            Finance Mortgage Loans<br/>

                            Finance Motor Vehicles<br/>

                            Financial Planning<br/>

                            Fire Protection Equipment<br/>

                            First Aid Supplies<br/>

                            Fish & Seafood Retail<br/>

                            Fishing Tackle<br/>

                            Fishing Charters<br/>

                            Floor Coverings<br/>

                            Floor Sanding & Polishing<br/>

                            Floors - Timber<br/>

                            Florist<br/>

                            Food & Beverages<br/>

                            Foreign Currency Exchanges<br/>

                            Formal Wear Hire - Mens<br/>

                            Four Wheel Drive Equipment & Accessories<br/>

                            Function Centres<br/>

                            Funeral Services<br/>

                            Furniture - Commercial<br/>

                            Furniture - Outdoor<br/>

                            Furniture - Retail<br/>

                            Furniture Removalist<br/>

                            Furniture Restoration & Repairs<br/>

                            Garage Doors<br/>

                            Gardeners<br/>

                            Gasfitters<br/>

                            Gas Appliances & Equipment<br/>

                            Gates<br/>

                            Gazebos<br/>

                            Glass Window Repairs - Home<br/>

                            Glass Window Repairs - Windscreens<br/>

                            Go Kart Tracks<br/>

                            Government Services<br/>

                            Granny Flats<br/>

                            Graphic Designers<br/>

                            Greengrocers<br/>

                            Gutter Cleaning<br/>

                            Guttering<br/>

                            Gymnastic Clubs<br/>

                            Hairdressers<br/>

                            Handbags<br/>

                            Hardware & Tools<br/>

                            Health Care Services NDIS<br/>

                            Health & Fitness Centres<br/>

                            Health Foods & Products<br/>

                            Health Insurance<br/>

                            Hearing Aids, Equipment & Services<br/>

                            Heating Appliances<br/>

                            Hi-Fi Equipment<br/>

                            Handyman Services<br/>

                            Home Health Care Aids & Equipment<br/>

                            Home Improvements<br/>

                            Hospitals - Private<br/>

                            Hospitals - Public<br/>

                            Hot Water Systems<br/>

                            Hypnotheraphy<br/>

                            Indoor Sports<br/>

                            Insurance Brokers<br/>

                            Interior Decorators<br/>

                            Irrigation Systems<br/>

                            Jewellers<br/>

                            Joinery<br/>

                            Jumping Castles<br/>

                            Karaoke<br/>

                            Kitchen Renovations<br/>

                            Landscape Contractors & Designers<br/>

                            Landscape Supplies<br/>

                            Laundries Services<br/>

                            Lawn Mowing Service<br/>

                            Lawn Mowers Retail & Repairs<br/>

                            Lawn & Turf Supplies<br/>

                            Lawyer<br/>

                            Legal Support Services<br/>

                            Letterbox Distribution<br/>

                            Libraries<br/>

                            Life Coaching<br/>

                            Lighting & Accessories - Retail<br/>

                            Limousine Hire<br/>

                            Liquor Store - Retail<br/>

                            Locksmith<br/>

                            Marinas<br/>

                            Martial Arts<br/>

                            Massage Therapy<br/>

                            Mechanical Engineers<br/>

                            Media & Communications<br/>

                            Medical Centres<br/>

                            Migration Consultants<br/>

                            Mobile Phone Repairs<br/>

                            Mobile Phones & Accessories<br/>

                            Mortgage Brokers<br/>

                            Motor Accessories - Retail<br/>

                            Motor Car Auctions<br/>

                            Motor Mechanics<br/>

                            Motor Mechanics - Mobile<br/>

                            Motorcycle Parts & Accessories<br/>

                            Motorcycles - New<br/>

                            Motorcycles - Used<br/>

                            Mufflers & Exhaust<br/>

                            Museums<br/>

                            Music Store - Retail<br/>

                            Music Lessons<br/>

                            Nail Salon<br/>

                            Naturopaths<br/>

                            Newsagents<br/>

                            Nightclubs<br/>

                            Nurseries - Retail<br/>

                            Nursing Homes<br/>

                            Office Supplies<br/>

                            Optometrists<br/>

                            Orthopaedic<br/>

                            Outboard Motors - New<br/>

                            Outboard Motors - Repairs<br/>

                            Painters & Decorators<br/>

                            Party Equipment Hire<br/>

                            Patent Attorneys<br/>

                            Personal Fitness Instructor<br/>

                            Pest Control<br/>

                            Pet Care Services<br/>

                            Pet Shops<br/>

                            Pharmacies & Chemists<br/>

                            Photocopying Services<br/>

                            Photographers<br/>

                            Physiotherapists<br/>

                            Plasterers<br/>

                            Plumbers<br/>

                            Podiatrists<br/>

                            Power Tools - Retail<br/>

                            Pressure Washing<br/>

                            Pre Schools<br/>

                            Printers<br/>

                            Professional Services<br/>

                            Property Management<br/>

                            Psychologists<br/>

                            Real Estate - Residential<br/>

                            Real Estate - Commercial & Industrial<br/>

                            Refrigeration - Commercial & Industrial<br/>

                            Refrigeration - Residential<br/>

                            Restaurants<br/>

                            Roller Shutters<br/>

                            Roof Restoration & Cleaning<br/>

                            Rubbish Removal<br/>

                            Scaffolding<br/>

                            Security Doors, Windows & Equipment<br/>

                            Security Guards & Patrol<br/>

                            Security Cameras<br/>

                            Security Alarms<br/>

                            Skin Treatment<br/>

                            Signage<br/>

                            Skip Bins<br/>

                            Skylights<br/>

                            Smash Repairer<br/>

                            Solicitors & Lawyers<br/>

                            Solar Energy<br/>

                            Sport Centres<br/>

                            Spray Tanning<br/>

                            Sporting Goods & Equipment<br/>

                            Squash Courts<br/>

                            Stonemason & Stonework<br/>

                            Storage<br/>

                            Strata Title Management<br/>

                            Supermarkets and Grocery Stores<br/>

                            Swimming Pool Construction<br/>

                            Swimming Lessons<br/>

                            Swimming Pools - Public<br/>

                            Swimming Pool Equipment & Chemicals<br/>

                            Telecommunications<br/>

                            Takeaway Foods<br/>

                            Tattoo Salon<br/>

                            Tennis Coaches<br/>

                            Tennis Court Hire<br/>

                            Ten Pin Bowling<br/>

                            Theatres<br/>

                            Tilers<br/>

                            Towing Services<br/>

                            Toys - Retail<br/>

                            Trailers & Equipment<br/>

                            Trailer Hire<br/>

                            Travel Agents<br/>

                            Transport Services<br/>

                            Tree Lopping & Stump Grinding<br/>

                            Tutoring<br/>

                            Tyres<br/>

                            Upholsterers<br/>

                            Utility Providers<br/>

                            Vacuum Cleaners - Residential<br/>

                            Veterinary Clinics<br/>

                            Wallpapers & wallcoverings<br/>

                            Wardrobes - Built Ins<br/>

                            Waterproofing Contractors<br/>

                            Websites<br/>

                            Wedding Cakes<br/>

                            Wedding Cars<br/>

                            Wedding Flowers<br/>

                            Wedding Hair & Beauty Services<br/>

                            Wedding Music & Entertainment<br/>

                            Wedding Photographer<br/>

                            Wedding Reception Venues<br/>

                            Weight Loss Treatment<br/>

                            Wheel Alignment & Balancing<br/>

                            Window Cleaning<br/>

                            Window Tinting<br/>

                            Yoga<br/>

                            <br/><br/>

                            “Service Region” is the region or suburbs or post-codes for which and Subscriber has a business listing on the website in respect of the Subscriber’s Service Category.

                            <br/><br/>

                            “the website” means www.goinglocal.com.au

                            <br/><br/>

                            “We”, “our” and “us” means Going Local (ABN 35 763 402 146), including its directors, officers, employees, contractors.

                            <br/><br/>

                            “You” or “Your” means the individual, company or other legal entity using our advertising and any associated services and by doing so, agree to these Terms.

                            <br/><br/>

                            FREE LISTING
                            <br/><br/>


                            If You register on our website for a free listing, we will make our advertising services available to You on a trial basis free of charge until the earlier of:

                            <br/><br/>

                            (a) the end of the free trial period for which You registered to use the applicable service(s); or

                            <br/><br/>

                            (b) the start date of any subscription ordered by You for such service(s); or
                            <br/><br/>


                            (c) termination by Us in our sole discretion.
                            <br/><br/>


                            Additional trial terms and conditions may appear on the trial registration web page. Any such additional terms and conditions are incorporated into these Terms by reference and are legally binding.

                            <br/><br/>

                            Any data you submit for use by us in advertising Services, and any customisations made to the services by or for you, during your free trial will be permanently lost unless you purchase a subscription to the same services as those covered by the trial. Notwithstanding Warranties, during the free trial the services are provided “as-is” without any warranty.

                            <br/><br/>

                            Please review the website and applicable documentation during the trial period so that You become familiar with the features and functions of the services before You subscribe as a Subscriber.

                            <br/><br/>

                            ACCOUNTS

                            <br/><br/>

                            You must subscribe and set up your account in order to advertise your business and Services on our website.
                            <br/><br/>


                            You agree to ensure that your subscription details are accurate and up to date.

                            <br/><br/>

                            PAYMENT TERMS & REFUNDS

                            <br/><br/>

                            Any Subscribers that are accepted by us pay an on-going monthly or discounted annual subscription fee to have exclusivity to all enquiries from users of this website for the Service Region and Service Category that is allocated to the Subscriber. In order to cancel your subscription, we require a minimum of five (5) business days written notice to cancel your subscription to the advertising services provided by the website;

                            <br/><br/>

                            By providing your credit card or other nominated payment method, you authorise us and agree to to have your monthly or annual subscription fee automatically deducted from your credit card or other nominated payment method unless you or we cancel the subscription in accordance with these Terms;

                            <br/><br/>

                            The current on-going monthly Premium subscription fee is:
                            <br/><br/>


                            $99.00 (AUD) incl. GST monthly.

                            <br/><br/>

                            The current discounted, on-going annual subscription fee is:

                            <br/><br/>

                            $990.00 (AUD) incl. GST annually.

                            <br/><br/>

                            We reserve the right to change the price of your subscription at any time with notice;

                            <br/><br/>

                            We retain the right to change the features and privileges of the subscription to the website, including, but not limited to, limiting or terminating the services;

                            <br/><br/>

                            Subscription fees are paid in advance before the services are activated all payments must be received in full prior to any Mini-site(s) being published;

                            <br/><br/>

                            Your first subscription period starts when you complete the verification process and pay your first subscription fee from your credit card or other nominated payment method you have assigned to your subscription account;
                            <br/><br/>


                            Payment is made via direct debit of the nominated credit card or other nominated payment method;
                            <br/><br/>


                            Direct debits are processed through EZIDEBIT PTY LTD;

                            <br/><br/>

                            EZIDEBIT PTY LTD have an independent direct debit authorisation agreement and terms and conditions that Subscribers will have to agree to online (or sign and return for hard copy) before their subscription can be activated;
                            <br/><br/>


                            If payment from the nominated credit card or other nominated payment method is unsuccessful, we will attempt to debit three (3) days after the original subscription payment was due;

                            <br/><br/>

                            If this payment is unsuccessful, the Mini-site(s) may be restricted and the Subscriber’s business details may not be displayed until all outstanding subscription fees are received;


                            <br/><br/>
                            Should any payment method you use fail or is later reclaimed by the bank or card issuer, the Subscriber agrees to pay any costs incurred by us;

                            <br/><br/>

                            By nominating a credit card or other nominated payment method to be debited, the Subscriber confirms that they are authorised to make such a purchase;


                            <br/><br/>
                            We are not required to provide any refund or part thereof to you for change of mind; and


                            <br/><br/>
                            Subscribers agree to receive all invoices by email to the email address you have given to us. It is your responsibility to ensure that this email address is working correctly.
                            <br/><br/>


                            SERVICE REGIONS

                            <br/><br/>

                            As we promote a ‘local’ service offering, Subscribers will be restricted to listing their business in Service Regions within 10km radius of their ‘Main business location’ as registered with ASIC. For the purposes of this website, each Service Region is defined as the suburb and postcode.

                            <br/><br/>

                            We define a Service Region and the geographical boundaries based on a population of residential homes. No discussion will be entered into regarding the associated boundaries for each Service Region.
                            <br/><br/>


                            Because of factors beyond our control, some Service Regions will offer higher population density and may result in higher traffic or increased results in terms of visibility.
                            <br/><br/>


                            Subscribers cannot negotiate bigger territories but can list in multiple Service Regions (subject to 10 km radius of their ‘Main business location’ as registered with ASIC).
                            <br/><br/>


                            If we cannot verify the business address via ASIC and/or ABN lookup, we may consider manually approving the Subscriber providing sufficient supporting documents can be provided.

                            <br/><br/>

                            Subscribers must operate from within 10 km of their pre-defined Service Region.


                            <br/><br/>
                            Subscribers must not subcontract work or resell jobs obtained through the website to people who have not cleared to our satisfaction.



                            If we discover that a Subscriber has relocated after registration but hasn’t notified us, we reserve the right to cancel or restrict the subscription without refund or with the agreement of the Subscriber we may to re-list their business details in the Service Region to which they have relocated if this is available.

                            <br/><br/>

                            CONTENT

                            <br/><br/>

                            We do not pre-screen or approve all Content, but we have the right (but not the obligation) in our sole discretion to refuse, delete or move any Content that is available on the website for violating the letter or spirit of the Terms or for any other reason;

                            <br/><br/>

                            As a Subscriber, you agree that you are responsible for any Content submitted to the website.
                            <br/><br/>


                            You may not submit Content or allow Content to be submitted that:
                            <br/><br/>


                            you do not have the right to submit;

                            <br/><br/>

                            is defamatory or in contempt of any legal or other proceedings;
                            <br/><br/>


                            is misleading or deceptive;

                            <br/><br/>

                            incites hatred or discrimination against any group of persons being a group defined by reference to colour, race, sex, origin, nationality or ethnic or national origins;

                            <br/><br/>

                            denounces religious or political beliefs;


                            <br/><br/>
                            includes religious or political material which is or is likely to be offensive;

                            <br/><br/>

                            is indecent, obscene, vulgar, pornographic, offensive or of doubtful propriety or of a menacing character or is likely to annoy or concern;

                            <br/><br/>

                            infringes any copyright, trade mark, patent or other intellectual property right of another person;

                            <br/><br/>

                            contains any unsolicited or unauthorised advertising or promotional material;

                            <br/><br/>

                            contains viruses or similar software or data which is designed to interrupt, destroy or limit the functionality of any computer software or hardware; or

                            <br/><br/>

                            impersonates any person or misrepresents your relationship with any person.


                            <br/><br/>
                            We reserve the right, in our absolute discretion, to pre-screen, refuse or remove any Content from the website without giving any reasons.

                            <br/><br/>

                            You understand and agree that we may retain server and backup copies of your submitted Content even if you have altered, removed or deleted your Content from public display.

                            <br/><br/>

                            USER REVIEWS & RATINGS (FEEDBACK)

                            <br/><br/>

                            The Mini-site may contain a review panel where Subscribers can receive star ratings and reviews from customers.

                            <br/><br/>

                            Subscribers must understand that we are not responsible for the feedback content that those customers post, or any damage or harm done by such feedback. In some exceptional circumstances, we may remove feedback, but only at our sole discretion.

                            <br/><br/>

                            We may perform checks on feedback to ensure authenticity and may at our sole discretion remove any feedback that we cannot verify.
                            <br/><br/>


                            Removal or lack of feedback does not alter the obligation on the Subscriber to pay any subscription fee.

                            <br/><br/>

                            If the subscription is cancelled for any reason by either party, the review data will be deleted permanently and cannot be reinstated if the business re-lists in the future.

                            <br/><br/>

                            WARRANTIES
                            <br/><br/>


                            As a condition of using the advertising services offered through our website, you warrant that:
                            <br/><br/>


                            Any Services you provide to customers who book your services directly or indirectly through our website complies with applicable laws, codes and regulations. In particular, you and any of your employees or independent contractors that provide Services to customers will have all relevant certifications, licenses and experience to provide the Services;

                            <br/><br/>

                            You have the ownership rights or the rights to use any Content you post to the website to advertise your services and any Content you post will not breach any third party rights;

                            <br/><br/>

                            you are not breaching any laws, regulations, standards, codes or guidelines by publishing the Content on the website, including, but not limited to the Australian Consumer Law (ACL) and any mandatory codes or guidelines published by the Australian Competition and Consumer Commission (ACCC) that are prescribed under the Competition and Consumer Act 2010 or voluntary industry codes or guidelines that apply to you;

                            <br/><br/>

                            All information, material and Content you post or advertise on the website or link to from our website is accurate, reliable, up-to-date, lawful, and not misleading or deceptive;

                            <br/><br/>

                            You will at all times abide by the Australian Consumer Law (ACL) requirements including but not limited to the re-supply or refund of any goods or services you supply or provide which do not meet ACL requirements; and

                            <br/><br/>

                            You also agree to ensure you adhere to good practice service standards for the supply of the Services to customers in a timely and responsive manner.
                            <br/><br/>


                            You acknowledge and agree:

                            <br/><br/>

                            We are a facilitator only for the purposes of permitting businesses and individuals to post their advertisement on our website but do not guarantee any bookings, business, increase in traffic or otherwise;

                            <br/><br/>

                            Any products or services you provide through your business is done directly and is your contractual relationship with the customer that may find you by visiting our website. Any disputes, issues, dealings, complaints are to be dealt with directly with the customer and you agree to indemnify us for customer claim which results directly or indirectly from your action or inaction; and
                            <br/><br/>


                            You will manage all customer disputes in good faith and in accordance with all Australian Consumer and other law requirements. We are not to be involved at any time and are not responsible directly or indirectly for any dispute with any customer regarding your provision of the Services.

                            <br/><br/>

                            We make no warranty that the website services will meet your requirements or be available on an uninterrupted, secure or error-free basis. We will do our best endeavors to ensure the website is always available and virus free but from time-to-time, and in some instances, this may not be the case as it may be out of our immediate control. We will notify you if the website becomes unavailable for any lengthy and unusual time period.

                            <br/><br/>

                            By using this website, you authorise us to use, reuse and to grant others the right to use and re-use your Content and any reproduction or similar in any form of media or technology for any purpose related to the website.
                            <br/><br/>


                            You agree and acknowledge that the website uses third party vendors and hosting partners to provide the necessary software, hardware, service and storage.

                            <br/><br/>

                            CANCELLATION AND TERMINATION


                            <br/><br/>
                            We have the right to terminate your subscription and remove your Content and any advertisement on our website for any reason, at any time. If we do not receive payment, we have right to remove your Content and any advertisement on our website and suspend your subscription. We will not be responsible for any lost files, revenue, information or anything related to such suspension or removal of your Content and any advertisement on our website.

                            <br/><br/>

                            Complaints: If we receive numerous complaints about your services, including but not limited to reliability, quality, responsiveness or any other issues related to the provision of your services, we may terminate your subscription. We will attempt to work with you to resolve such issues where possible but we are not to be involved in any dispute with any of your customers or clients.

                            <br/><br/>

                            You agree that we may, in our sole discretion, terminate or suspend your access to the website with or without notice and for any reason, including, without limitation, breach of these Terms. Any suspected fraudulent, abusive or illegal activity may be grounds for terminating your relationship and may be referred to the appropriate law enforcement authorities.

                            <br/><br/>

                            If we have reasonable grounds to suspect the information you provide on your profile, account or advertisement on our website or on your website is untrue, inaccurate or incomplete; if we receive numerous complaints about your good or services, any advertisement or action or inaction you have taken with a customer or that you have breached any Terms; if your content is offensive, or for any other reason including if, in our opinion, you have breached the purpose of our website, at our sole discretion we have the right to immediately withdraw your advertisement and information links and terminate your account. We may also deny the use of our website and services to you in the future and are not obligated to return any subscription or registration monies in the event of any breach.
                            <br/><br/>


                            Upon such termination, regardless of the reasons, your right to use the website immediately ceases and you acknowledge and agree we may immediately deactivate or delete your account and all related information and files. We may also bar you from any further access to our website. We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection with such termination or suspension.

                            <br/><br/>

                            We are not required to provide any refund or part thereof to you for such termination of your subscription.

                            <br/><br/>

                            MODIFICATION OF WEBSITE AND CONTENT

                            <br/><br/>

                            We reserve the right at any time and from time to time to remove, delete, alter or amend any Content or the website. In particular, if we believe the Content to be inappropriate, potentially breach regulations, receive complaints or for any other reason and in our reasonable discretion, we may remove or modify Content at any time without notice. We shall not be liable to you or any third party for any modification when it is required.

                            <br/><br/>

                            We may, but are not obligated to, remove any Content that we determine in our sole discretion may be unlawful, offensive, defamatory, obscene, or otherwise objectionable or violates a third party’s copyright, trade marks or other intellectual property rights or these Terms.

                            <br/><br/>

                            We reserve the right to disclose your name and any other personal details of any Subscriber to any law enforcement authority or other competent authority or to any person for the purpose of legal proceedings, prosecution, investigation or any breach, alleged breach of the law or these Terms.


                            <br/><br/>
                            LIABILITY


                            <br/><br/>
                            We cannot influence the outcome of any particular enquiry and does not guarantee that Subscribers will secure work from customers. Subscribers agree to pay the six (6) monthly subscription fee whether or not they win work as a result of advertising on the website.

                            <br/><br/>

                            In addition, you agree to fully indemnify Going Local, our officers, employees, agents and successors in rights, and keep us at all times fully indemnified from and against any claims, demands, costs, damages or awards (direct, indirect, actual, consequential or incidental) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, made by customers or any third party which arises out of or in any way related to: (a) the Mini-site and your Content and any advertisement on the website or the services we provide you on the website; or (b) any disputes arising from any transactions for business you conduct with any customer.
                            <br/><br/>


                            Our liability is governed by the Australian Consumer Law (ACL), including any consumer guarantees provided by the ACL that cannot be excluded or modified.  All other conditions and warranties which may be implied by custom, or statute are expressly excluded by these Terms.

                            <br/><br/>

                            Our liability to you will not exceed the amount actually paid by you to for your subscription.

                            <br/>  <br/>

                            Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.

                            <br/>  <br/>

                            No agency, partnership, joint venture, or employment is created as a result of these Terms, and you do not have any authority of any kind to bind us in any respect whatsoever.

                            <br/>  <br/>

                            YOUR PRIVACY

                            <br/>  <br/>

                            We are committed to protecting your privacy. We use the information we collect about you to maximise the services that we provide to you. We at all times, respect the privacy and confidentiality of the information provided by you and adhere to the Australian Privacy Principles. Please read our separate Privacy Policy carefully.

                            <br/>  <br/>

                            You may change your details at any time by advising us in writing via email. All information we receive from our customers, is protected by our secure servers. goinglocal.com.au secure server software encrypts all customer information before it is sent to us. Furthermore, all of the customer information we collect is secured against unauthorised use or access. Credit card or payment information is not stored by us on our servers.

                            <br/>  <br/>

                            THIRD PARTIES
                            <br/>  <br/>


                            We do not and will not sell or deal in personal or customer information. We may however use in a general sense without any reference to your name, your information to create marketing statistics, identify user demands and to assist it in meeting customer needs generally. In addition, we may use the information that you provide to improve our website and services but not for any other use.

                            <br/>  <br/>

                            DISCLOSE YOUR INFORMATION

                            <br/>  <br/>

                            We may be required, in certain circumstances, to disclose information in good faith and where we are required to do so in the following circumstances: by law or by any court; to enforce the terms of any of our customer agreements; or to protect the rights, property or safety of our customers or third parties.

                            <br/>  <br/>

                            We reserve the right to disclose the name and any other personal details of any user of the website to any law enforcement authority or other competent authority or to any person for the purpose of legal proceedings, prosecution, investigation or any breach, alleged breach of the law or these Terms.

                            <br/>  <br/>

                            INTELLECTUAL PROPERTY

                            <br/>  <br/>

                            All custom graphics, icons, logos and service names are registered trademarks, copyright, trade or service marks of Going Local.

                            <br/>  <br/>

                            All other trademarks or service marks within this website are the property of their respective owners. Nothing in these Terms grants you any right to use any trademark, service mark, logo, and/or our name or any of our Subscribers.

                            <br/>  <br/>

                            You are solely responsible for obtaining written permission before re-using any copyrighted material that is available on this website. Any unauthorised use of the materials appearing on this website may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.

                            <br/>  <br/>

                            TRANSFER AND ASSIGNMENT

                            <br/>  <br/>

                            Should Going Local merge, sell or otherwise change control of our business or this website to a third-party, we reserve the right, without giving notice or seeking any additional consent from you, to transfer or assign the personal information, content and rights that we have collected from you and any agreements we have made with you.

                            <br/>  <br/>

                            GOVERNING LAW

                             <br/>  <br/>

                            These Terms are governed by the laws of New South Wales, Australia, which are in force from time to time and both you and we agree to submit to the exclusive jurisdiction of the Courts of New South Wales for determining any dispute concerning these Terms.


                        </p>

                    </div>
                </div>
            </div>

        </div>
    )
}