import React,{useEffect} from "react";
import logo from '../../../images/logo-hd-white.png'
import {Link, useNavigate} from "react-router-dom";
import UserSession from '../../../utils/UserSession';
import config from "../../../Config";
import axios from "axios/index";
import useSession, { UseSessionProvider } from 'react-session-hook';


function Header (props){
    const navigate = useNavigate();
    const session = useSession();
    var logout = (e)=>{

    };

    useEffect(()=> {
        //console.log(UserSession.getToken());
        //console.log(UserSession.getUserData().name);



        window.$('#logoutBtn').click(function () {
            window.$('#logoutLoadingScreen').show();

            axios.post(config.links.user_logout, {
                user_id: UserSession.getUserData().id,
            })
                .then(function (response) {
                    window.$('#logoutLoadingScreen').hide();
                    console.log(response);
                    UserSession.clear();
                    navigate('/login', { replace: false });

                })
                .catch(function (error) {
                    console.log(error);
                    window.$('#logoutLoadingScreen').hide();
                });



        });

        window.$('#logoutBtn2').click(function () {
            window.$('#logoutLoadingScreen').show();

            axios.post(config.links.user_logout, {
                user_id: UserSession.getUserData().id,
            })
                .then(function (response) {
                    window.$('#logoutLoadingScreen').hide();
                    console.log(response);
                    UserSession.clear();
                    navigate('/login', { replace: false });

                })
                .catch(function (error) {
                    console.log(error);
                    window.$('#logoutLoadingScreen').hide();
                });



        });


    });


    const handleSubmit = (e)=>{
        e.preventDefault();



    };



    return(

        <div id="mainHeader">

            <div className="valign-wrapper" id="logoutLoadingScreen" style={{ display: 'none'}}>
                <h5 className="center white-text" style={{ margin: 'auto'}}>Logging out please wait...</h5>
            </div>

            <div className="valign-wrapper" id="savingLoadingScreen" style={{ display: 'none'}}>
                <h5 className="center white-text" style={{ margin: 'auto'}}>Loading please wait...</h5>
            </div>


            <div id="top-bar" className="green hide-on-med-and-down" style={{ paddingTop: 3, paddingBottom: 3}}>
                <div className="container">
                    <div className="row">
                        <div className="col s10 m10 l11 white-text">
                           <div style={{ margin: 0, marginTop: 3}}>
                               <span style={{ marginRight: 50, fontSize: 13, fontWeight: 300}}>POPULAR CATEGORIES</span>
                               <ul className="popular-categories">
                                   <li>TRADES</li>
                                   <li>MARKETPLACE</li>
                                   <li>REAL ESTATE</li>
                                   <li>CARS & VEHICLES</li>
                                   <li>NDIS PROVIDERS</li>
                                   <li>JOB VACANCIES</li>
                               </ul>
                           </div>

                        </div>
                        <div className="col s2 m2 l1 white-text topbar-left-icons">
                            <i className="material-icons ">home</i>
                            <i className="material-icons">live_help</i>
                        </div>
                    </div>
                </div>
            </div>
            <header>
                <nav style={{backgroundColor: '#000000'}} className="z-depth-0 main-navigation ">
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <div className="nav-wrapper">
                                    <a href="/" >

                                        <img className="brand-logo" src={logo} style={{ marginTop: 10, width: 120, padding: 0 }}/>
                                    </a>

                                    <a href="#" data-target="mobile-demo2" className="button-collapse hide-on-large-only sidenav-trigger">
                                        <i className="material-icons green-text">menu</i></a>

                                    <ul className="right hide-on-med-and-down main-nav-links">
                                        <li ><Link to="/categories" className="white-text"><i className="material-icons left green-text">list</i><span>Categories</span></Link></li>
                                        <li>
                                            <Link to="/user/listings/add" className="white-text" ><i className="green-text material-icons left">add_circle_outline</i>Add Business</Link>

                                        </li>
                                        <li><a className="dropdown-trigger white-text" href="#!" data-target="dropdown123"><i className="green-text material-icons left">account_circle</i> {
                                            UserSession.getToken() ? UserSession.getUserData().name: 'My Account'
                                        }<i className="material-icons right ">arrow_drop_down</i></a></li>
                                    </ul>
                                    <ul className="sidenav sidenav-collapsible leftside-navigation collapsible  menu-shadow" id="mobile-demo2">
                                        <li className="bold">
                                            <Link to="/" className="waves-effect waves-cyan " >
                                                <i className="material-icons">home</i>
                                                <span className="menu-title" data-i18n="Chat">Home</span>
                                            </Link>
                                        </li>
                                        <li className="bold">
                                            <Link to="/categories" className="waves-effect waves-cyan " >
                                                <i className="material-icons">list</i>
                                                <span className="menu-title" data-i18n="Chat">Categories</span>
                                            </Link>
                                        </li>

                                        <li className="bold">
                                            <Link to="/user/listings/add" className="waves-effect waves-cyan " >
                                                <i className="material-icons">add_circle_outline</i>
                                                <span className="menu-title" data-i18n="Chat">Add Business</span>
                                            </Link>
                                        </li>

                                        <li className="bold">
                                            <Link to="#"  className="collapsible-header waves-effect waves-cyan " >
                                                <i className="material-icons">account_circle</i>
                                                <span className="menu-title" data-i18n="Dashboard">{
                                                    UserSession.getToken() ? UserSession.getUserData().name: 'My Account'
                                                }</span>
                                            </Link>
                                            <div className="collapsible-body">


                                                {
                                                    UserSession.getToken() ?
                                                        <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                                            <li>
                                                                <Link to="/user/profile" >
                                                                    <i className="material-icons">radio_button_unchecked</i>
                                                                    <span data-i18n="Modern">Profile</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a id="logoutBtn" ><i className="material-icons">radio_button_unchecked</i> Log Out</a>
                                                                <form id="logoutForm1"  ><input type="hidden" value={UserSession.getUserData().id} /></form>
                                                            </li>

                                                        </ul>
                                                        :
                                                        <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                                            <li>
                                                                <Link to="/login" >
                                                                    <i className="material-icons">radio_button_unchecked</i>
                                                                    <span data-i18n="Modern">Log In</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/register" >
                                                                    <i className="material-icons">radio_button_unchecked</i>
                                                                    <span data-i18n="eCommerce">Register</span>
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                }


                                            </div>
                                        </li>
                                    </ul>


                                </div>
                            </div>
                        </div>


                    </div>

                    {
                        UserSession.getToken() ?
                            <ul id="dropdown123" className="dropdown-content" style={{ top: 64 }}>
                                <li><a href="/user/profile">Profile</a></li>
                                <li className="divider"></li>
                                <li>
                                    <a id="logoutBtn2" >Log Out</a>
                                    <form id="logoutForm2"  ><input type="hidden" value={UserSession.getUserData().id} /></form>
                                </li>
                            </ul>
                            :
                            <ul id="dropdown123" className="dropdown-content" style={{ top: 64 }}>
                                <li><a href="/login">Login</a></li>
                                <li className="divider"></li>
                                <li><a href="/register">Register</a></li>
                            </ul>
                    }



                </nav>
            </header>
        </div>



    )



}

export default Header;