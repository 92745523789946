import React, { useState, FC, useCallback, useEffect } from 'react';
import UserSession from '../../../utils/UserSession';

import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'

  const GoogleRecaptchaExample: FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    const [noOfVerifications, setNoOfVerifications] = useState(0);
    const [dynamicAction, setDynamicAction] = useState('homepage');
    const [actionToChange, setActionToChange] = useState('');

    const clickHandler = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const result = await executeRecaptcha('dynamicAction');

        setToken(result);
        setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
    }, [dynamicAction, executeRecaptcha]);

    const handleTextChange = useCallback(event => {
        setActionToChange(event.target.value);
    }, []);

    const handleCommitAction = useCallback(() => {
        setDynamicAction(actionToChange);
    }, [actionToChange]);

    useEffect(() => {
        if (!executeRecaptcha || !dynamicAction) {
            return;
        }

        const handleReCaptchaVerify = async () => {
            const token = await executeRecaptcha(dynamicAction);
            console.log(token);
            UserSession.setCaptchaToken(token);
        };

        handleReCaptchaVerify();
    }, [executeRecaptcha, dynamicAction]);

    return (
        <div></div>
    );
};

  export default GoogleRecaptchaExample;