import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate, useParams} from "react-router-dom/index";
import useSession from "react-session-hook/index";
import UserSession from "../../../utils/UserSession";
import axios from "axios/index";
import Config from "../../../Config";
import Breadcrumbs from '../../backend/templates/BreadCrumbs';
import homebanner from '../../../images/homebanner-v2.jpg';
import {Link} from "react-router-dom";
import TempLogo from "../../../images/temp.png";
import ReviewItem from "../templates/ReviewItem";
import { convertFromRaw } from 'draft-js';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import CaptchaComponent from '../templates/captcha';
import RatingStars from '../templates/RateIo';
import $ from 'jquery';

import ReactGA from 'react-ga';
ReactGA.initialize(Config.links.GA_TRACKING_ID);


export default function Listingpage(props) {

    const session = useSession();
    const navigate = useNavigate();
    let profile = [];
    const {listingId} =  useParams();
    const {slocation} =  useParams();
    const {sname} =  useParams();
    const [listingData , setlistingData] = useState([]);
    const [name, setName] = useState("");
    const [user , setUser] = useState(null);
    const [profiles , setProfiles] = useState([]);
    const [about , setAbout] = useState('');
    const [reviews , setReviews] = useState([]);
    const [mostrate , setmostrate] = useState(0);
    const [mostrateStr , setmostrateStr] = useState("");


    const [lmEmail, setLmEmail] = useState('');
    const [lmName, setLmName] = useState('');
    const [lmPhoneNumber, setlmPhoneNumber] = useState('');
    const [lmMessage, setlmMessage] = useState('');
    const [lmSent, setlmSent] = useState(false);
    const [lmError, setlmError] = useState("");

    const [rSent, setrSent] = useState(false);


    const [breacrumbsitems , setbreacrumbsitems] = useState([

    ]);





    const [message, setMessage] = useState('');

    const [rate , setRate] = useState('');

    const handleChange = (e) => {
        const name =  e.target.name;
        const value =  e.target.value;

        switch (name){
            case "message":
                setMessage(value);
                break;
            case "rate":
                setRate(value);
                break;
            case "lmMessage":
                setlmMessage(value);
                break;
            case "lmEmail":
                setLmEmail(value);
                break;
            case "lmName":
                setLmName(value);
                break;
            case 'lmPhoneNo':
                setlmPhoneNumber(value);
                break;
        }

    };



    const handleSubmit = (e) => {
        e.preventDefault();
        window.$('#reviewSubmitBtn').attr('disabled' ,  true);
        window.$('#reviewSubmitBtn').text('Loading...');
        const formData = new FormData();
        formData.append('listing_id' ,listingId );
        formData.append('user_id' , UserSession.getUserData().id );
        formData.append('message' , message );
        formData.append('rate' , rate );
        const config = {headers: { Authorization: `Bearer ${UserSession.getToken()}` }};
        axios.post(Config.links.reviews, formData, config)
            .then(function (response) {
                window.$('#reviewSubmitBtn').attr('disabled' ,  false);
                window.$('#reviewSubmitBtn').text('Submit');
                if(response.data.message === "ok"){
                    setrSent(true);
                    console.log(response);
                    setReviews(response.data.reviews);
                }
//
            })
            .catch(function (error) {
                //console.log(error);
                window.$('#reviewSubmitBtn').attr('disabled' ,  false);
                window.$('#reviewSubmitBtn').text('Submit');
            });
    };


    const handleSubmitQuery = (e) => {
        e.preventDefault();

    };

    const onCallClick = (e)=>{
        e.preventDefault();
        window.location.href="tel:"+listingData.contact_no;
        ReactGA.event({
            category: "Listing Activity",
            action: "Call",
            label: listingData.name,
        });
    };




    useEffect(() => {


        $(".toReviews").click(function (){
            $('html, body').animate({
                scrollTop: $("#leave-a-message").offset().top -140
            }, 1000);
        });


        ReactGA.pageview(window.location.pathname + window.location.search);

        window.$("#lmPhoneNo").ForceNumericOnly();
        window.initializeRating();

        // Get Listing Data
        const url = Config.links.getDirectory;
        axios.get(url,{params : {id: listingId}})
            .then(function (response) {
                setmostrate( response.data.data.rate );

                window.$("#ratingNumbers").prop("data-rateyo-rating", response.data.data.rate.toString()+"%" );
                window.initializeRating();
                //console.log(response);

                if(response.data.data.directory.website != null && response.data.data.directory.website != 'null'){
                    let string = response.data.data.directory.website;
                    if (!~string.indexOf("http")) {
                        string = "http://" + string;
                    }
                    response.data.data.directory.website = string;
                }

                setbreacrumbsitems(
                    [
                        {name: 'home', link: '/'},
                        {name: response.data.data.directory.category, link: '/find/categories/'+response.data.category_details.slug},
                        {name: sname, link: '#'},
                    ]
                );

                setlistingData(response.data.data.directory);
                setUser(response.data.data.user);
                setProfiles(response.data.data.profile);
                setName( response.data.directory.name);
                setAbout(response.data.data.directory.about);


            })
            .catch(function (error) {
                //console.log(error);
            });
        // end get listing data

        // Get reviews

        axios.get(Config.links.reviews,{params : {did: listingId}})
            .then(function (response) {
                //console.log(response);
                setReviews(response.data.reviews);

            })
            .catch(function (error) {
                //console.log(error);
            });


        if(!UserSession.getToken()){
            //navigate('/user/login', {replace: true})
        }else {
            if(UserSession.getUserProfile().length > 0){



            }else {
                navigate('/user/profile/edit', {replace: true});
            }

        }

    }, []);


    const [token, setToken] = useState("");





    const YourReCaptchaComponent = () => {
        const { executeRecaptcha } = useGoogleReCaptcha();
        let doneruning = false;

        // Create an event handler so you can call the verification on button click event or form submit
        const handleReCaptchaVerify = useCallback(async () => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const token = await executeRecaptcha('yourAction');
            // Do whatever you want with the token
            //setToken(token);

            window.$('#lmSubmitBtn').attr('disabled' ,  true);
            window.$('#lmSubmitBtn').text('Loading...');
            const formData = new FormData();
            formData.append('listing_id' ,listingId );
            formData.append('message' , lmMessage );
            formData.append('name' , lmName );
            formData.append('email' , lmEmail );
            formData.append('phone_no' , lmPhoneNumber );
            formData.append('token' , token );
            const config = {headers: { Authorization: `Bearer ${UserSession.getToken()}` }};
            axios.post(Config.links.leaveMessage, formData, config)
                .then(function (response) {
                    console.log(response);
                    window.$('#submitBtn').attr('disabled' ,  false);
                    window.$('#submitBtn').text('Submit');

                    if(response.data.message === "OK"){
                        setlmSent(true);
                        ReactGA.event({
                            category: "Listing Activity",
                            action: "Email",
                            label: listingData.name,
                        });
                    }else {
                        setlmError(response.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    window.$('#lmSubmitBtn').attr('disabled' ,  false);
                    window.$('#lmSubmitBtn').text('Submit');
                });



            //console.log(token);
        }, []);

        // You can use useEffect to trigger the verification as soon as the component being loaded
        useEffect(() => {
            //handleReCaptchaVerify();
        }, [handleReCaptchaVerify]);

        return (
            <button onClick={handleReCaptchaVerify} type="submit" id="lmSubmitBtn" className="btn btn-large green width-100 mt-4">Submit</button>

        )

    };





    return(
        <div id="listingpage">


            <div className="listing-mobile-actions hide-on-med-and-up z-depth-5">
                <div className="row">
                    <div className="col s6 no-padding">
                        <a href="#" onClick={e => onCallClick(e)}  className="btn btn-large btn-flat white-text"><i className="material-icons left md-18 ">phone_android</i><span > {listingData.contact_no} </span></a>
                    </div>
                    <div className="col s6 no-padding black">
                        <a   className="toReviews btn btn-large btn-flat  white-text black"><i className="material-icons left md-18 ">message</i><span > Message </span></a>
                    </div>
                </div>
            </div>


            <div className="breadcrumbs-dark pb-0 pt-0" id="breadcrumbs-wrapper" style={{ backgroundColor: '#242424', paddingTop: '0px!important'}}>
                <div className="container">
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <h4 className=" mt-0 mb-0 black-text"><span>{name}</span></h4>
                            <ol className="breadcrumbs mb-0 mt-0 pt-1 pb-1">
                                {
                                    Array(breacrumbsitems.length).fill(1).map((el, i) =>
                                        <li key={i} className="breadcrumb-item " style={{ textTransform: 'lowercase'}}><Link style={{ color: '#ccc'}} to={breacrumbsitems[i].link} >{breacrumbsitems[i].name}</Link></li>
                                    )
                                }
                            </ol>
                        </div>

                    </div>
                </div>
            </div>


            <div className="banner-holder" style={{   backgroundImage : listingData.banner != null ? "url("+Config.links.asset_link+listingData.banner+")" : homebanner}}>

                <div className="banner-wrapper">
                    <div className="banner-image">
                    </div>
                </div>

                <div className="page-listing-items pt-5 pb-5">

                    <div className="container">
                        <div className="row">
                            <div className="col s12 m3 l2  ">
                                <img className=" rounded" src={listingData.logo != null ? Config.links.asset_link+listingData.logo: TempLogo} style={{ width: '100%' ,  height : 165 , objectFit : 'contain'}}/>
                            </div>
                            <div className="col s12 m8 l10">
                                <div className="white-text center-align-sm left-align-lg left-align-md">
                                    <p  className="mb-0 yellow-text listing-category-item" style={{ display: 'inline-block'}}><i className="material-icons left md-18 ">dashboard</i> {listingData.category}</p>
                                    <h2 className="white-text mt-1 mb-0 left-align-md center-align-sm  ">{listingData.name}</h2>

                                    {/*<div className="mt-0 mb-0 center-align-sm left-align-lg left-align-md">
                                        &nbsp;{mostrate / 20} Star{ (mostrate / 20) > 1 ? 's' : ''} | <a   className="toWriteReviews yellow-text" style={{ cursor: 'pointer' }}>Rate this business</a></div>*/}
                                    <p className="mt-1" style={{ textTransform: "", display: 'inline-block'}}>
                                        <i className="material-icons left" style={{ fontSize: 20, marginRight: 5}}>location_on</i>
                                        { ( listingData.address != null &&  listingData.address != 'null' ? ''+ listingData.address+',' : '')} {listingData.suburb}, {listingData.state} {listingData.postcode}

                                    </p>
                                    {
                                        listingData.website != null &&  listingData.website != 'null' ?
                                            <p className="icon-text mt-0">< a className="white-text website-link" target="_blank"  href={listingData.website}><i className="material-icons left  ">public</i>Visit Website</a> </p>
                                            : ""
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container pt-1 pb-0" >
                        <div className="row">
                            <div className="col s12 center-align-sm left-align-lg left-align-md no-padding">
                                <a href="#" onClick={e => onCallClick(e)}   className="btn btn-large btn-flat listing-call-btn "><i className="material-icons left md-18 ">phone_android</i><span style={{ fontSize: 18}}> {listingData.contact_no} </span></a>

                                <a   className="toReviews btn btn-large btn-flat listing-call-btn white-text listing-call-btn"><i className="material-icons left md-18 ">message</i><span style={{ fontSize: 18}}> Leave a message </span></a>

                            </div>
                        </div>
                    </div>




                </div>

            </div>

            <div className="container mb-4 mt-3 pb-1" id="reviews" >
                <div className="row">
                    <div className="col s12 pt-2">


                        {
                            listingData.about != null && listingData.about != 'null' && listingData.about != '<p>null</p>' ?

                                <div className="center-align-sm left-align-md left-align-lg" >
                                    <h4 className="center-align-sm left-align-md left-align-lg" >{listingData.name}</h4>
                                    <p className="center-align-sm left-align-md left-align-lg" dangerouslySetInnerHTML={{__html: listingData.about}} ></p>
                                </div>


                                : ""
                        }




                        {/*<div className="pt-5">

                            <div className="row">
                                <div className="col s12 m12  l12">

                                    {
                                        listingData.about != null &&  listingData.about != 'null'?
                                            <div>
                                                <h4 style={{ marginBottom: 0}}>About Us</h4>
                                                <div dangerouslySetInnerHTML={{__html: listingData.about}} />
                                            </div> : ''
                                    }



                                </div>

                            </div>
                        </div>*/}

                        {/*{
                            reviews.length > 0 ?
                                <div className="mt-2">
                                    <h4 style={{ marginBottom: 0}} className="mt-0">Reviews</h4>
                                     {mostrate/20}.0 Stars
                                    | {reviews.length} Review(s)
                                </div> : ""
                        }*/}




                        {/*<div className="">
                            {
                                reviews.length > 0 ?
                                    Object.keys(reviews).map((key, i) => (
                                        <ReviewItem key={key} listing={reviews[i]} />
                                    ))
                                    :
                                    ""
                            }
                        </div>*/}

                        {/*<div className="write-a-review-main-holder mt-0">

                            <div className="row">
                                <div className="col s12 m12 l12   left-align-lg left-align-md " >
                                    <h4 style={{ marginBottom: 0}}>Rate this business</h4>

                                    {
                                        !UserSession.getToken() ?
                                            <Link to="/user/login" className="btn btn-small z-depth-0 green">Log in to write a review.</Link>
                                            :


                                    <div>

                                        {
                                            rSent ?
                                                <p className="grey lighten-3 padding-2">
                                                    <strong className="black-text">Your feedback has been submitted.</strong>
                                                    <br/>
                                                    Thank you for taking the time to give us your valuable feedback.
                                                </p>
                                                :

                                                <form onSubmit={e => handleSubmit(e)}>
                                                    <div className="row">
                                                        <div className="col s12 m6 l6 no-padding">

                                                        <div className="row">
                                                            <div className="col s6 m6 l6 no-pad">
                                                                <p>
                                                                    <label>
                                                                        <input required={true} onChange={e => handleChange(e)} name="rate" type="radio" value="100"/>
                                                                        <span><span style={{padding:0, display: 'inline-block'}} className="num-rate" data-rateyo-read-only="true" data-rateyo-rating="100%"></span> 5.0</span>
                                                                    </label>
                                                                </p>
                                                                <p>
                                                                    <label>
                                                                        <input required={true} onChange={e => handleChange(e)} name="rate" type="radio" value="80"/>
                                                                        <span><span style={{padding:0, display: 'inline-block'}} className="num-rate" data-rateyo-read-only="true" data-rateyo-rating="80%"></span> 4.0</span>
                                                                    </label>
                                                                </p>
                                                                <p>
                                                                    <label>
                                                                        <input required={true} onChange={e => handleChange(e)} name="rate" type="radio" value="60"/>
                                                                        <span><span style={{padding:0, display: 'inline-block'}} className="num-rate" data-rateyo-read-only="true" data-rateyo-rating="60%"></span> 3.0</span>
                                                                    </label>
                                                                </p>
                                                            </div>
                                                            <div className="col s6 m6 l6">
                                                                <p>
                                                                    <label>
                                                                        <input required={true} onChange={e => handleChange(e)} name="rate" type="radio" value="40"/>
                                                                        <span><span style={{padding:0, display: 'inline-block'}} className="num-rate" data-rateyo-read-only="true" data-rateyo-rating="40%"></span> 2.0</span>
                                                                    </label>
                                                                </p>
                                                                <p>

                                                                    <label>
                                                                        <input required={true} onChange={e => handleChange(e)} name="rate" type="radio" value="20"/>
                                                                        <span><span style={{padding:0, display: 'inline-block'}} className="num-rate" data-rateyo-read-only="true" data-rateyo-rating="20%"></span> 1.0</span>
                                                                    </label>
                                                                </p>
                                                            </div>
                                                        </div>





                                                        </div>
                                                        <div className="col s12 m12 l12 no-padding" style={{ marginTop: 20}}>
                                                            <button type="suburb" id="reviewSubmitBtn" className="btn btn-large green">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                        }



                                    </div>
                                    }
                                </div>
                            </div>

                        </div>*/}

                    </div>
                    <div className="col s12 m12 l12 pb-5">

                        <div className="" id="leave-a-message">
                            <h4 className="pt-4 center-align-sm left-align-lg left-align-md">Leave a message</h4>
                            <p className="mt-0  center-align-sm left-align-lg left-align-md">Use the form below to get in touch.</p>







                                  {/* <Link to="/user/login" className="btn btn-small z-depth-0 green">Log in to leave a message.</Link>
                                    */}



                            <div>
                                {
                                    lmSent ? <p className="grey lighten-3 padding-4">
                                            <strong className="black-text">Thank you for reaching us.</strong>
                                            <br/>
                                            Your message has been sent. Please check your email later for other notifications.
                                        </p> :

                                        <form onSubmit={e => handleSubmitQuery(e)}>
                                            <div>
                                                <strong>Name</strong>
                                                <div className="custom-input no-icon">
                                                    <input  onChange={e => handleChange(e) } id="lmName" type="text" name="lmName" placeholder=""/>
                                                </div>
                                            </div>

                                            <div>
                                                <strong>Email Address<span className="red-text">*</span></strong>
                                                <div className="custom-input no-icon">
                                                    <input  onChange={e => handleChange(e) } id="lmEmail" required type="text" name="lmEmail" placeholder=""/>
                                                </div>
                                            </div>
                                            <div>
                                                <strong>Phone Number<span className="red-text">*</span></strong>
                                                <div className="custom-input no-icon">
                                                    <input className="lmPhoneNo" onChange={e => handleChange(e) } required id="lmPhoneNo"  type="text" name="lmPhoneNo" placeholder=""/>
                                                </div>
                                            </div>

                                            <div className="mt-2">
                                                <strong>Your Message</strong>
                                                <textarea onChange={e => handleChange(e)} name="lmMessage" style={{ border: '2px solid #cccccc',
                                                    borderRadius: 5,
                                                    height: 150,
                                                    width: '100%',
                                                    padding: 10}}></textarea>
                                            </div>

                                            <p className="red-text center mb-0">{lmError}</p>

                                            <GoogleReCaptchaProvider reCaptchaKey="6LfSB8ofAAAAACTHI8ZZQJSfgM_hlPe7S5lGXjMz">
                                                <YourReCaptchaComponent />
                                            </GoogleReCaptchaProvider>
                                        </form>
                                }



                            </div>


                        </div>

                    </div>
                </div>
            </div>

        </div>
    )

}