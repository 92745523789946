import React  from  'react';

export default function SearchResultItem(props) {

    return(
        <div className="row " style={{ marginTop: 10, marginBottom: 20}}>
            <div className="col s12 m5 l3 center-align-sm left-align-lg">
                <strong className="green-text " style={{ fontSize: 20, fontWeight: 300}}>{props.company_name}</strong>
            </div>
            <div className="col s12 m5 l4 center-align-sm left-align-lg">
                <strong style={{ fontSize: 20, fontWeight: 300}}>{props.location}</strong>
            </div>
            <div className="col s12 m5 l5 right-align-lg right-align-md center-align-sm">
                <button className="btn btn-small btn-flat green white-text"><i className="material-icons left">playlist_add</i> CLAIM</button>
                &nbsp;
                <button className="btn btn-small btn-flat blue white-text"><i className="material-icons left">visibility</i> VIEW</button>
            </div>
        </div>
    );

}