import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import UserSession from '../../../utils/UserSession';
import {useNavigate} from "react-router-dom";

export default function ProfileNavs(props) {

    const navigate = useNavigate();

    useEffect(()=>{
        console.log(UserSession.getToken());
        if(!UserSession.getToken()){
            navigate('/register/business', {replace: true})
        }
    }, []);

    return(
        <div style={{ marginTop: 60}}>
            <ul className="profile-nav-link">
                <li className={ ''+(props.class === 'profile'? 'active' : '') } ><Link to="/user/profile" >Profile</Link></li>
                {
                    UserSession.getUserData().type !== 1 ?
                        <li className={ ''+(props.class === 'my-listing'? 'active' : '') }><Link to="/user/listings">My Listings</Link></li>
                    : ''
                }

                <li className={ ''+(props.class === 'edit-profile'? 'active' : '') }><Link to="/user/profile/edit">Edit Profile</Link></li>
                <li className={ ''+(props.class === 'update-password'? 'active' : '') }><Link to="/user/profile/update-password">Update Password</Link></li>
            </ul>


            {
                UserSession.getUserData().type === 1 ?

                    <div className="card green z-depth-0">
                        <div className="card-content white-text">
                            <span className="card-title">Grow your business 24/7</span>
                            <p>
                                A website is there when you can’t be.
                                The easy, affordable way to grow your business in this year.
                            </p>
                        </div>
                        <div className="card-action">
                            <a href="/user/upgrade" className="lime-text text-accent-1">Get Started Now</a>
                        </div>
                    </div> : ''
            }


        </div>
    )
}