import React, { useEffect, useState} from 'react';
import SearchForm from "../templates/SearchForm.Component";
import {useNavigate, useParams} from "react-router-dom/index";
import ListingItemVersion1 from '../templates/ListingItem.version1';
import webIcon from '../../../images/worldwide.png';
import Config from "../../../Config";
import axios from "axios/index";
import mapicon from '../../../images/map.png';
import {
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ReCaptchaComponent from '../templates/YourReCaptchaComponentHidden';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-224678357-1";
ReactGA.initialize(TRACKING_ID);

export default function CategoryResults(props) {

    const navigate = useNavigate();
    const [slocation, setLocation] = useState("");
    const [companyNname, setcompanyNname] = useState("");

    const [listings, setlistings] = useState([]);
    const [category_name, set_category_name] = useState("");
    const [isSearching, setisSearching] = useState(false);


    let {cat_name} = useParams();



    useEffect(()=>{

        ReactGA.pageview(window.location.pathname + window.location.search);

        setisSearching(true);
        axios.get(Config.links.searchCategory, {params: {slug: cat_name}})
            .then((response) => {
                setisSearching(false);
                setlistings(response.data.data);
                set_category_name(response.data.name);
                console.log(response.data);
            })
            .catch((error) => {
                setisSearching(false);
                console.log(error);
            })



    }, [cat_name]);






    return(
        <div id="serachResultpage" style={{ paddingTop: 0, minHeight: '100vh'}}>


            <GoogleReCaptchaProvider
                reCaptchaKey="6LfSB8ofAAAAACTHI8ZZQJSfgM_hlPe7S5lGXjMz">
                <ReCaptchaComponent/>
            </GoogleReCaptchaProvider>

            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="col s12 breadcrumb-link-holders " >
                            <a  className="breadcrumb">Home</a>
                            <a href={'/categories'} className="breadcrumb">Categories</a>
                            <a className="breadcrumb">{cat_name}</a>


                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <h2 className="mt-1 mb-1 left-align-sm">{category_name}</h2>
                            <p className="mt-0 mb-0">{listings.length} "{category_name}" found </p>
                        </div>
                    </div>
                </div>
            </div>
           <div className="container pt-2" >
               <div className="row">
                   <div className="col s12 m12 l8" >

                       {
                           listings.length > 0 ?

                           Array(listings.length).fill(1).map((el, i) =>
                               <ListingItemVersion1 key={i} data={listings[i]}/>
                           )

                               :

                               <h2>No data found.</h2>
                       }


                   </div>


                   <div className="col s12 m12 l4  serachResultpage-left-panel">

                   </div>
               </div>
           </div>




        </div>
    )
}