import React from 'react';
import {Link} from "react-router-dom";
import '../css/BreadCrumbs.css';

export default function BreadCrumbs(props) {
    return(
        <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col s10 m6 l6">
                        <h4 className=" mt-0 mb-0 black-text"><span>{props.title}</span></h4>
                        <ol className="breadcrumbs mb-0">
                            {
                                Array(props.items.length).fill(1).map((el, i) =>
                                    <li key={i} className="breadcrumb-item"><Link to={props.items[i].link} >{props.items[i].name}</Link></li>
                                )
                            }
                        </ol>
                    </div>

                </div>
            </div>
        </div>
    )
}