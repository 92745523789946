import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

export default function SuggestABusinessForm(props) {

    useEffect(() => {


        window.loadCategories();

        window.$('#categoryselection').on("change", e=> props.handleChange(e));


        window.$('.dropify').dropify({
            messages: {
                'default': 'Drag and drop a file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove':  'Remove',
                'error':   'Ooops, something wrong happended.'
            },
            error: {
                'fileSize': 'The file size is too big ({{ value }} max).',
                'minWidth': 'The image width is too small ({{ value }}}px min).',
                'maxWidth': 'The image width is too big ({{ value }}}px max).',
                'minHeight': 'The image height is too small ({{ value }}}px min).',
                'maxHeight': 'The image height is too big ({{ value }}px max).',
                'imageFormat': 'The image format is not allowed ({{ value }} only).'
            }
        });


    }, []);


    return(
        <div className="suggest-a-business-form-holder" >
            <div className="row">
                <div className="input-field col s12">
                    <input onChange={e => props.handleChange(e)} placeholder=""  required={true} id="business-name" name="business_name" type="text" className="validate"/>
                        <label htmlFor="business-name" className="active">Business Name*</label>
                </div>
            </div>

            <div className="row">
                <div className="input-field col s12 m12 l12 ">
                    <input onChange={e => props.handleChange(e)} placeholder=""   id="building-address" name="building_address" type="text" className="validate"/>
                    <label htmlFor="building-address" className="active">Business Address</label>
                </div>

            </div>



            <div className="row">
                <div className="input-field col s12 m3 l3">
                    <input onChange={e => props.handleChange(e)} placeholder="" required={true}  id="country" name="country" type="text" className="validate"/>
                    <label htmlFor="country" className="active">Country*</label>
                </div>

                <div className="input-field col s12 m3 l3">
                    <input onChange={e => props.handleChange(e)} placeholder="" required={true}  id="state" name="state" type="text" className="validate"/>
                    <label htmlFor="state" className="active">State*</label>
                </div>

                <div className="input-field col s12 m3 l3 ">
                    <input onChange={e => props.handleChange(e)} placeholder=""required={true}   id="business-suburb" name="business_suburb" type="text" className="validate"/>
                    <label htmlFor="business-suburb" className="active">Suburb*</label>
                </div>

                <div className="input-field col s12 m3 l3">
                    <input onChange={e => props.handleChange(e)} placeholder="" required={true}  id="postcode" name="postcode" type="text" className="validate"/>
                    <label htmlFor="postcode" className="active">Post Code*</label>
                </div>
            </div>

            <div className="row">
                <div className="input-field col s12 m4 l5 ">
                    <input onChange={e => props.handleChange(e)} placeholder=""   id="contact-number" name="contact_number" type="text" className="validate"/>
                    <label htmlFor="contact-number" className="active">Contact Number*</label>
                </div>
                <div className="input-field col s12 m8 l7">
                    <input onChange={e => props.handleChange(e)} placeholder=""   id="email" name="email" type="text" className="validate"/>
                    <label htmlFor="email" className="active">Email Address*</label>
                </div>
            </div>

            <div className="row">
                <div className="input-field col s12 m8 l8">
                    <input onChange={e => props.handleChange(e)} placeholder=""   id="website" name="website" type="text" className="validate"/>
                    <label htmlFor="website" className="active">Website</label>
                </div>
                <div className="input-field col s12 m4 l4">
                    <input onChange={e => props.handleChange(e)} placeholder=""   id="abn" name="abn" type="text" className="validate"/>
                    <label htmlFor="abn" className="active">ABN</label>
                </div>
            </div>

            <div className="row" style={{ marginTop: 20}}>
                <div className="col s12 center">
                    <h5>What type of business are you adding?</h5>
                    <p>Search and select a keyword that best describes the category related to the business.<br/>This will help us make sure the listing appears in relevant search results.</p>
                </div>
            </div>


            <div className="row" style={{ marginTop: 20}}>
                <div className="col s12">
                    <strong>Select Category<span className="red-text">*</span></strong>
                    <div className="custom-input no-icon select" style={{ marginTop: 0}}>
                        <select required={true} onChange={e=> props.handleChange(e)} className="browser-default" id="categoryselection" name="category">

                        </select>
                    </div>
                    <p className="center green-text"><Link to="/categories" className="green-text" style={{ fontWeight: 500}}>Not sure which category?</Link></p>

                </div>
            </div>


            <div className="row ">
                <div className="col s12 card z-depth-0 grey lighten-3 center padding-2"  style={{ border: '1px solid #b7b4b4' ,  paddingBottom: 50}}>
                    <h5>Make the listing stand out!</h5>
                    <p>Add a photo of the shop front or company logo</p><br/>
                    <label>
                        <img className="imagePrev bordered-image rounded"  style={{ width: 165, height : 165 , objectFit : 'cover'}}/><br/>
                        <input style={{ border: '1px solid #b7b4b4' ,  padding: 5}} type="file" name="banner" id="imgField" onChange={e => props.handleChange(e)}/>
                    </label>

                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <p className="bold center">All suggestions are reviewed before being published.</p>
                </div>
            </div>

            <div className="row" style={{ marginTop: 20}}>
                <div className="col s12 center">
                    <label>
                        <input type="checkbox" className="filled-in" required={true} />
                        <span>I agree to the <Link to="/" className="green-text">Terms of use*</Link></span>
                    </label>
                </div>
            </div>

            {
                !props.isUploading ?
                    <div className="row" style={{ marginTop: 30}}>
                        <div className="col s12">

                            {Object.keys(props.errors).map((key, i) => (

                                Object.keys(props.errors[key]).map((key2, j) => (
                                    <p key={i} className="red-text center" style={{ marginTop: 0, marginBottom: 10}}>{props.errors[key][key2]}<br/></p>
                                ))
                            ))}

                            <button   type="submit" className="btn btn-flat btn-large width-100 green white-text">SUBMIT</button>
                        </div>
                    </div>
                    :
                    <div className="valign-wrapper" style={{ maxWidth: 170, margin: 'auto', marginTop: 50}}>
                            <div className="preloader-wrapper small active">
                                <div className="spinner-layer spinner-green-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                                </div>
                            </div>
                            &nbsp;&nbsp;Submitting entry..
                    </div>
            }



            {
                props.hasError?
                    <div className="row" style={{ marginTop: 30}}>
                        <div className="col s12">
                            <div className="card-alert card red " style={{ maxWidth: 400, margin: 'auto'}}>
                                <div className="card-content white-text">
                                    <p>Error : {props.errorMessage}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    : ''
            }


            <div className="row" style={{ marginTop: 0}}>
                <div className="col s12 center-align">
                    <Link to="/add-business" className="btn-flat mb-1 waves-effect green-text">
                        <i className="material-icons left">arrow_back</i> Back to Search
                    </Link>
                </div>
            </div>

        </div>
    )
}