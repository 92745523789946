import React, {useEffect, useState} from 'react';
import signupimg from '../../images/signup-img.png'
import logoBlack from '../../images/logo-hd-black.png'
import {Link, useNavigate} from "react-router-dom";
import UserSession from '../../utils/UserSession';
import config from "../../Config";
import axios from "axios/index";
import useSession, { UseSessionProvider } from 'react-session-hook';







export default function LoginPage(props) {

    let navigate = useNavigate();
    useEffect(()=>{

        if(UserSession.getToken()){
            navigate('/user/profile');
        }




    }, []);


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const session = useSession();

    const handleOnChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
        }

    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        window.$('#submitBtn').attr('disabled' ,  true);
        window.$('#submitBtn').text('Loading...');

        axios.post(config.links.user_login, {
            email: email,
            password: password
        })
            .then(function (response) {
                console.log(response);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('LOG IN');

                if(response.data.message === 'ok'){
                    session.setSession({ token: response.data.access_token });
                    UserSession.setToken(response.data.access_token);
                    UserSession.setUserData(response.data.user);
                    UserSession.setUserProfile(response.data.profile);
                    UserSession.setlisting_space(response.data.listing_space);
                    navigate('/user/profile', { replace: false });
                }else {

                    window.$('#resultTxtHolder').show();
                    window.$('#resultTxt').text(response.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('LOG IN');
            });

    };





    return (
        <div className="container " id="register-page" style={{  paddingTop: 50, paddingBottom: 50} }>

            <div className="row">
                <div className="col s12 m12 l10 push-l1   z-depth-5 register-form-main-holder">


                    <div className="row equal-height">
                        <div className="col s12 m6 l6  hide-on-small-and-down center-align-sm left-align-lg" style={{ padding: 40, paddingTop: 60}}>
                            <Link to='/'><img src={logoBlack} style={{ width: 150}}/> </Link>
                            <div className="center hide-on-small-and-down">
                                <img src={signupimg} style={{ width: '100%', maxWidth: 300}}/>
                                <p>Copyright © 2022 Going Local</p>
                            </div>
                        </div>
                        <div className="col s12 m6 l6  white padding-4 min-height-100" style={{ paddingTop: 30, paddingBottom: 70}}  >

                            <p style={{ marginTop: 50, marginBottom: 0, fontWeight: 400, color: '#999'}}>Welcome back!</p>
                            <h4 style={{ marginTop: 0, marginBottom: 1}}>Log in account </h4>
                            <p style={{marginTop: 5, marginBottom: 0, fontWeight: 400, color: '#999'}}>Don't have an account? <Link to="/register" className="green-text">Register here</Link></p>

                            <form style={{ marginTop: 30}} onSubmit={e => handleOnSubmit(e)}>

                                <strong>Email Address<span className="red-text">*</span></strong>
                                <div className="custom-input">
                                    <i className="material-icons dp48">mail_outline</i>
                                    <input required type="email" onChange={e => handleOnChange(e)} name="email" placeholder="youremail@domain.com..."/>
                                </div>
                                <strong>Password<span className="red-text">*</span></strong>
                                <div className="custom-input" style={{ marginBottom: 5}}>
                                    <i className="material-icons dp48">lock_outline</i>
                                    <input required  type="password" onChange={e => handleOnChange(e)} name="password" placeholder="type your password here..."/>
                                </div>
                                <Link to="/user/forgot-password" className="green-text">Forgot password?</Link>

                                <div className="card-alert  gradient-45deg-red-pink mt-2" id="resultTxtHolder" style={{ display: 'none'}}>
                                    <div className="card-content white-text">
                                        <i className="material-icons">error</i>
                                        <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">

                                        </p>


                                    </div>

                                </div>

                                <button id="submitBtn" className="btn btn-large green width-100 mt-3">Log In</button>
                                <br/>
                                <div className="center ">
                                    <p>Copyright © 2022 Going Local</p>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )

}