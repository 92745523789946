import React from 'react';
import { ReactSession  } from 'react-client-session';



const token = "ACCESS_TOKEN";
const user = "USER_DATA";
const profile = "USER_PROFILE";
const captchtoken = "CAPTCHA_TOKEN";
const listing_space = "LISTING_SPACE";
ReactSession.setStoreType("cookie");

let UserSession = {

    getToken: ()=> {

        try {
            return ReactSession.get(token);
        } catch (e) {
            return null;
        }


    },

    setToken: (_token)=> {
        ReactSession.set(token, _token);
    },

    getUserData:  ()=> {
        return ReactSession.get(user);
    },

    setUserData: (_user) => {
        ReactSession.set(user, _user);
    },

    clear: () => {
        ReactSession.remove(user);
        ReactSession.remove(token);
        ReactSession.remove(profile);
    },

    checkIfLogin : ()=>{
        if(ReactSession.get(token)){
            return true
        }else {
            return false;
        }
    },

    setUserProfile : (_profile)=>{
        ReactSession.set(profile, _profile);
    },

    getUserProfile : ()=>{

        try {
            return ReactSession.get(profile);
        } catch (e) {
            return [];
        }
    },

    setCaptchaToken : (_token)=>{
        ReactSession.set(captchtoken, _token);
    },



    getCaptchaToken : ()=>{
        try {
            return ReactSession.get(captchtoken);
        } catch (e) {
            return null;
        }
    },

    setlisting_space : (_listing_space)=>{
        ReactSession.set(listing_space, _listing_space);
    },

    getListing_space : ()=>{
        try {
            return ReactSession.get(listing_space);
        } catch (e) {
            return null;
        }
    },








};

export default UserSession;