import React,{useEffect, useState} from 'react';

import tempLogo from '../../../images/main-logo-01.png';
import config from '../../../Config';
import {Link} from "react-router-dom";
import UserSession from '../../../utils/UserSession';
import axios from "axios";
import Config from "../../../Config";

import ReactGA from 'react-ga';


export default function ListingItemVersion1(props) {

    const [about , setAbout] = useState("");
    const [aboutShort , setaboutHsort] = useState(true);

    useEffect(()=>{
        //console.log(props.data);
        window.initializeRating();
        window.$('.modal').modal();

        if(props.data.about != null && props.data.about !== 'null'){
            if(props.data.about.length > 200){
                const str = props.data.about.slice(0, 200);
                setAbout(str+"...");
            }else {
                setAbout(props.data.about);
            }
        }





    }, []);

    const readMoreClcik = (e)=>{
        e.preventDefault();

        if(aboutShort){
            setAbout(props.data.about);
            setaboutHsort(false);
        }else {
            const str = props.data.about.slice(0, 200);
            setAbout(str+"...");
            setaboutHsort(true);
        }
    };

    const callBtnClick = (e) => {
        e.preventDefault();
        window.location.href="tel:"+props.data.contact_no;

        //console.log("GA event:", "Listing Activity", ":", "Call", ":", props.data.name);
        ReactGA.event({
            category: "Listing Activity",
            action: "Call",
            label: props.data.name,
        });

    };

    const messageBtnClick = (e) =>{
        e.preventDefault();
        window.$('#modal'+props.data.id).modal('open');
    };

    const [lmEmail, setLmEmail] = useState('');
    const [lmName, setLmName] = useState('');
    const [lmPhoneNumber, setlmPhoneNumber] = useState('');
    const [lmMessage, setlmMessage] = useState('');
    const [lmSent, setlmSent] = useState(false);
    const [lmError, setlmError] = useState("");

    const handleChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;

        switch (name){
            case "lmMessage":
                setlmMessage(value);
                break;
            case "lmEmail":
                setLmEmail(value);
                break;
            case "lmName":
                setLmName(value);
                break;
            case 'lmPhoneNo':
                setlmPhoneNumber(value);
                break;
        }
    };

    const handleSubmit = (e)=>{
        e.preventDefault();
        window.$('#lmSubmitBtn'+props.data.id).attr('disabled' ,  true);
        window.$('#lmSubmitBtn'+props.data.id).text('Loading...');
        const formData = new FormData();
        formData.append('listing_id' ,props.data.id );
        formData.append('message' , lmMessage );
        formData.append('name' , lmName );
        formData.append('email' , lmEmail );
        formData.append('phone_no' , lmPhoneNumber );
        formData.append('token' , UserSession.getCaptchaToken() );
        axios.post(Config.links.leaveMessage, formData, config)
            .then(function (response) {
                console.log(response);
                window.$('#lmSubmitBtn'+props.data.id).attr('disabled' ,  true);
                window.$('#lmSubmitBtn'+props.data.id).text('Submit');

                if(response.data.message === "OK"){
                    setlmSent(true);

                    ReactGA.event({
                        category: "Listing Activity",
                        action: "Email",
                        label: props.data.name,
                    });

                }else {
                    setlmError(response.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
                window.$('#lmSubmitBtn'+props.data.id).attr('disabled' ,  false);
                window.$('#lmSubmitBtn'+props.data.id).text('Submit');
            });
    };





    return(
        <div key={props.data.id} className="row" style={{ marginBottom: 20}}>
            <div className="col s12" style={{ paddingLeft: 10, paddingRight: 10}}>
                <div className="listing-item template-1 white " style={{ paddingBottom: 0}}>
                    <div className="row pt-2 pb-2">

                        <div className="col s12 m4 l4 center hide-on-med-and-up">
                            <div className="logo-container">
                                {
                                    props.data.logo != null ?
                                        <img className="listResultLogo" src={config.links.asset_link+props.data.logo} />
                                    : ""
                                }

                            </div>
                        </div>


                        <div className="col s12 m8 l8 list-item-details-holder left-align-sm left-align-lg left-align-md">
                            {
                                props.data.owner_type === 1 ?
                                    <h2 className="green-text left-align-lg left-align-sm left-align-md" style={{marginTop: 0, marginBottom: 5, fontSize: 20}}>{props.data.name}</h2>
                                    :
                                    <h2 className="green-text left-align-lg left-align-sm left-align-md" style={{marginTop: 0, marginBottom: 5, fontSize: 20}}><Link to={"/"+props.data.category_details.slug+"/"+props.data.suburb+"-"+props.data.state+ "-"+ props.data.postcode+"/"+props.data.slug+"/"+props.data.id }>{props.data.name}</Link></h2>
                            }
                            <p  style={{ marginTop: 0, marginBottom: 5}}><i className="material-icons left " style={{ fontSize: 20, marginRight: 3}}>location_on</i> {props.data.suburb+" "+props.data.state+" "+props.data.postcode}</p>



                            {
                                props.data.about != null && props.data.about != 'null' && props.data.about != '<p>null</p>' ?
                                    <div className="mb-5">
                                        <p className="listingShortDescItem" dangerouslySetInnerHTML={{__html: about}} ></p>

                                        {
                                            props.data.about.length > 200
                                            ?
                                            <a href={"/"+props.data.category_details.slug+"/"+props.data.suburb+"-"+props.data.state+ "-"+ props.data.postcode+"/"+props.data.slug+"/"+props.data.id } className="readmoreBtn"  >{aboutShort ? 'Read More' : 'Read Less'}</a>
                                            : ""
                                        }

                                    </div>
                                    : ""
                            }

                        </div>

                        <div className="col s12 m4 l3 center hide-on-small-and-down">
                            <div className="logo-container">
                                {
                                    props.data.logo != null ?
                                        <img src={config.links.asset_link+props.data.logo} className="listResultLogo" />
                                        : ""
                                }

                            </div>
                        </div>

                    </div>

                    <div className="row" style={{ borderTop: "1px solid #efefef"}}>
                        {/*<div className="col s12 m6 l4 " style={{ paddingLeft: 10, paddingRight: 10, paddingTop:5, paddingBottom:10}}>
                            <div className=" left-align-lg center-align-sm">
                                <div>
                                    <div style={{padding:0, display: 'inline-block'}} className="num-rate" data-rateyo-read-only="true" data-rateyo-rating="0%"></div> 0.0
                                </div>
                                <p style={{ margin: 0}}>Be the first to rate this business - <a href={"/directory/"+props.data.suburb+"/"+props.data.id+"/"+props.data.slug} className="green-text ">Rate Now</a></p>

                            </div>
                        </div>*/}


                        <div className="col s6 m6 l6 padding-2" >
                            <div className=" list-item-details-holder center-align-lg center-align-sm">
                                <a href="#" className="modal-trigger" onClick={e => messageBtnClick(e)} >✉ Leave A Message</a>
                            </div>
                        </div>
                        <div className="col s6 m6 l6 padding-2" style={{borderLeft: "1px solid #efefef"}}>
                            <div className=" list-item-details-holder center-align-lg center-align-sm">
                                 <a href={"#"} onClick={e => callBtnClick(e)} >☏ Call Now</a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <form onSubmit={e => handleSubmit(e)}>
                <div id={"modal"+props.data.id} className="modal modal-fixed-footer small">
                <div className="modal-content">
                    <h4>Write a message</h4>
                    <p>Use the form below to get in touch.</p>
                    <p className="red-text mb-2"> {lmError != '' ? 'Error: '+lmError : ''}  </p>

                    {
                        lmSent ? <p className="grey lighten-3 padding-4">
                            <strong className="black-text">Thank you for reaching us.</strong>
                            <br/>
                            Your message has been sent. Please check your email later for other notifications.
                        </p> :

                            <div>
                                <div>
                                    <strong>Name<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input required  onChange={e => handleChange(e) } id="lmName" type="text" name="lmName" placeholder=""/>
                                    </div>
                                </div>
                                <div>
                                    <strong>Email<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input required onChange={e => handleChange(e) } id="lmEmail" type="text" name="lmEmail" placeholder=""/>
                                    </div>
                                </div>
                                <div>
                                    <strong>Contact No.<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input required  onChange={e => handleChange(e) } id="lmPhoneNo" type="text" name="lmPhoneNo" placeholder=""/>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <strong>Your Message</strong>
                                    <textarea  onChange={e => handleChange(e)} name="lmMessage" style={{ border: '2px solid #cccccc',
                                        borderRadius: 5,
                                        height: 150,
                                        width: '100%',
                                        padding: 10}}></textarea>
                                </div>
                            </div>
                    }




                </div>
                <div className="modal-footer">
                    <a  className="modal-action modal-close waves-effect waves-green btn-flat ">Close</a>
                    <button id={"lmSubmitBtn"+props.data.id} type="submit" className="modal-action  waves-effect waves-green btn green">Send</button>
                </div>
            </div>
            </form>

        </div>
    );
}