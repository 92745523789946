import react,{useEffect, useState} from 'react';
import ProgressBar from '../templates/ProgressLoadingCircle';
import Pagination from '../templates/Pagination';
import { useParams} from "react-router-dom";
import config from "../../../Config";
import {useNavigate} from "react-router-dom";

import ReactGA from 'react-ga';
ReactGA.initialize(config.links.GA_TRACKING_ID);

export default function CategoryPage() {

    const [isLoading, setIsLoading] = useState(false);
    const [current_page, set_current_page] = useState(0);
    const [data, set_data] = useState([]);
    const [first_page_url, set_first_page_url] = useState("");
    const [last_page, set_last_page] = useState("");
    const [next_page_url, set_next_page_url] = useState("");
    const [path, set_path] = useState("");
    const [per_page, set_per_page] = useState(40);
    const [prev_page_url, set_prev_page_url] = useState("");
    const [to, set_to] = useState(0);
    const [total, set_total] = useState(0);
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(()=>{

        ReactGA.pageview(window.location.pathname + window.location.search);


        if(id === undefined){
            getCategories(1);
        }else {
            getCategories(id);
        }

        window.initSeacrCategory();
        window.$('#catSearchBar').on("select2:selecting", function(e) {
            console.log(e.params.args.data);

            navigate('/find/categories/'+e.params.args.data.id);

        });

        window.$('.select2-selection__arrow').hide();



    },[id]);

    const getCategories =  (pageNum)=>{

        setIsLoading(true);
        window.$.ajax({
            url :config.links.getCategoriesWithPagination+pageNum,
            type: 'get',
            success : function (response) {
                setIsLoading(false);
                const dataJson = JSON.parse(response);
               // console.log(dataJson);

                set_data(dataJson.data);
                set_current_page(dataJson.current_page);
                set_first_page_url(dataJson.first_page_url);
                set_last_page(dataJson.last_page);
                set_next_page_url(dataJson.next_page_url);
                set_path(dataJson.path);
                set_per_page(dataJson.per_page);
                set_prev_page_url(dataJson.prev_page_url);
                set_total(dataJson.total);
                set_to(dataJson.to);


            },
            error: function (e) {
                console.log(e);
                setIsLoading(false);
            }
        });
    };



    return(
        <div className="container" id="category-page">
            <div className="row">
                <div className="col s12 center">
                    <h2>BROWSE BY CATEGORY</h2>

                    <div className="custom-input no-icon select mb-3  " style={{ maxWidth: 500, margin: 'auto'}}>
                        <i className="material-icons " style={{ marginTop: 10}}>search</i>
                        <select className="browser-default select2-data-ajax " id="catSearchBar" >
                        </select>
                    </div>

                    <p style={{ maxWidth: 600, margin: 'auto'}}>
                        Find below our entire list of categories in alphabetical order.
                        You can also search for the category in our above search bar for your convenience.
                    </p>
                </div>

                {
                    id > last_page ?
                        <div className="row" >
                            <div className="col s12 center">
                                <h5 style={{ paddingTop: 50}}>No more categories to show.</h5>
                            </div>
                        </div>
                        :''
                }





                {
                    isLoading ?
                        <div className="col s12" style={{ marginTop: 50}}>
                            <div className="center">
                                <ProgressBar size="small"/>
                            </div>
                        </div>
                        : ''
                }


            </div>


            <div className="category-holder" style={{ maxWidth: 1000, margin: 'auto', marginTop: 50, marginBottom: 50}}>

                <ul className="category-list">
                    {
                        Array(data.length).fill(1).map((el, i) =>

                            <li><a href={'/find/categories/'+data[i].slug}> <i className="material-icons dp48 left">chevron_right</i> {data[i].name}</a></li>


                        )
                    }
                </ul>

            </div>



            <div className="row">
                <div className="col s12" style={{ marginBottom: 50}}>
                    <Pagination style={{ textAlign: 'center'}} currentPage={current_page} lastPage={last_page} />
                </div>
            </div>

        </div>
    )
}