import React,{useEffect, useState} from  'react';
import  '../css/page-users.css';
import Breadcrumbs from '../templates/BreadCrumbs';
import ProfileNavs from '../templates/ProfileNavs';
import {useNavigate} from 'react-router-dom';
import UserSession from '../../../utils/UserSession';
import Config from '../../../Config';
import axios from "axios/index";
import TempLogo from "../../../images/usericon.png";
import useSession, { UseSessionProvider } from 'react-session-hook';

function EditProfilePage(props) {

    const navigate = useNavigate();

    let username = "";
    let usernameLenght = [];


    const [fisrt_name, set_fisrt_name] = useState("");
    const [last_name, set_last_name] = useState('');
    const [phone_no, set_phone_no] = useState('');
    const [dob, set_dob] = useState('');
    const [address, set_address] = useState('');
    const [state, set_state] = useState('');
    const [suburb, set_suburb] = useState('');
    const [postcode, set_postcode] = useState('');
    const [profile, setprofile] = useState(null);
    const [imgFile, setimgFile] = useState(null);
    const session = useSession();

    const handleOnchange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'firstname':
                set_fisrt_name(value);
                break;
            case 'lastname':
                set_last_name(value);
                break;
            case 'phoneno':
                set_phone_no(value);
                break;
            case 'dob':
                set_dob(value);
                break;
            case 'state':
                set_state(value);
                break;

            case 'postcode':
                set_postcode(value);
                break;
            case 'profile':
                setimgFile(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = function (event) {

                    window.$('.imagePrev').attr("src" , event.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                break;
        }
    };

    useEffect(()=>{

        if(!UserSession.getToken()){
            navigate('/login', {replace: true})
        }else if(UserSession.getUserData().email_verified_at == null){
            navigate('/user/verify', {replace: true})
        }else {
             username = UserSession.getUserData().name;
             usernameLenght = username.split(" ");
              window.loadCategories();
            window.$('#stateSelection').on("change", e=> handleOnchange(e));
            window.$('#suburbSelection').on("change", e=> handleOnchange(e));
            window.$("#phoneno").ForceNumericOnly();




            window.$('#suburbSelection').on("select2:selecting", function(e) {

               console.log(e.params.args.data);
               set_state(e.params.args.data.state);
               set_postcode(e.params.args.data.postcode);

            });



             if(UserSession.getUserProfile().length > 0){
                 set_fisrt_name(JSON.parse(UserSession.getUserProfile()[0].full_name).first_name);
                 set_last_name(JSON.parse(UserSession.getUserProfile()[0].full_name).last_name);
                 set_phone_no(UserSession.getUserProfile()[0].contact_number);
                 set_dob( (UserSession.getUserProfile()[0].dob == null || UserSession.getUserProfile()[0].dob == 'null' ? '' : UserSession.getUserProfile()[0].dob ) );
                 set_address(UserSession.getUserProfile()[0].address);
                 set_state(UserSession.getUserProfile()[0].state);
                 set_suburb(UserSession.getUserProfile()[0].suburb);
                 set_postcode(UserSession.getUserProfile()[0].postcode);
                 setprofile(UserSession.getUserProfile()[0].profile);


                 const option = window.$('<option selected>'+UserSession.getUserProfile()[0].suburb+'</option>').val(UserSession.getUserProfile()[0].suburb);
                 window.$('#suburbSelection').append(option).trigger('change');

             }else {
                 const fname = UserSession.getUserData().name;
                 const words = fname.split(" ");

                 set_fisrt_name(words[0]);
                 set_last_name( words.length >= 1 ? words[1] : '' );
                 console.log(words);
             }


        }




        window.$(document).ready(function(){
            window.$('.datepicker').datepicker({
                format: 'mm/dd/yyyy',
                yearRange: [1950,2022],
                onSelect : (_date)=>{
                    console.log(_date.toLocaleDateString("en-US"));
                    set_dob(_date.toLocaleDateString("en-US"));
                }
            });
        });



    }, []);

    const handleSubmit = (e)=>{
        e.preventDefault();

        const formData = new FormData();
        let url = "";
        if(UserSession.getUserProfile().length > 0){
            url = Config.links.updateProfile;
            formData.append("id" , UserSession.getUserProfile()[0].id);
        }else {
            url = Config.links.createProfile;
        }

        formData.append('user_id' , UserSession.getUserData().id);
        formData.append('full_name' ,'{"first_name":"'+fisrt_name+'", "last_name":"'+last_name+'"}' );
        formData.append('contact_number' , phone_no );
        //formData.append('address' ,address );
        //formData.append('state' ,state );
        //formData.append('suburb' ,suburb );
        //formData.append('postcode' ,postcode );
        formData.append('dob' ,dob );
        if(imgFile != null){
            formData.append('img' , imgFile );
        }

        window.$('#submitBtn').attr('disabled' ,  true);
        window.$('#submitBtn').text('Loading...');
        const config = {headers: { Authorization: `Bearer ${UserSession.getToken()}` }};
        axios.post(url, formData,config)
            .then(function (response) {
                 console.log(response);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('SAVE CHANGES');

                if(response.data.message === 'ok'){
                    UserSession.setUserProfile(response.data.profile);
                    window.$('#resultTxt').text("Update Successfully.");
                }else {
                    window.$('#resultTxt').text(response.data.message);
                }

            })
            .catch(function (error) {
                // console.log(error);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('SAVE CHANGES');
            });



    };





    const breacrumbsitems = [
        {name : 'home', link : '/'},
        {name : 'profile', link : '/user/profile'},
        {name : 'edit profile', link : '#'},
    ];

    const title = "Edit Profile";

    return(
        <div id="profilepage" style={{ paddingTop: 0, paddingBottom: 50}}>
            <div className="container">
                <div className="row">

                    <div className="col s12 m9 l9  no-padding">
                        <Breadcrumbs items={breacrumbsitems} title={title}/>

                        <form onSubmit={e => handleSubmit(e)} style={{ padding: 8, marginTop: 20}}>

                            <div className="row">

                                <div className="col s12 m4 l3">
                                    <label>
                                        <img className="imagePrev bordered-image rounded" src={ profile != null ? Config.links.asset_link+profile : TempLogo} style={{ width: 165, height : 165 , objectFit : 'cover'}}/>
                                        <input type="file" name="profile" onChange={e => handleOnchange(e)}/>
                                    </label>
                                </div>

                                <div className="col s12 m4 l5">
                                    <strong>First Name<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input value={fisrt_name} onChange={e => handleOnchange(e) } id="fisrtname" required type="text" name="firstname" placeholder=""/>
                                    </div>
                                    <strong>Last Name<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input value={last_name} onChange={e => handleOnchange(e) } required type="text" name="lastname" placeholder=""/>
                                    </div>
                                </div>

                                <div className="col s12 m4 l4">
                                    <strong>Phone No.</strong>
                                    <div className="custom-input no-icon">
                                        <input required value={phone_no} onChange={e => handleOnchange(e) } className="phone-format" id="phoneno" type="text" name="phoneno" placeholder=""/>
                                    </div>
                                   {/* <strong>Date of birth</strong>
                                    <div className="custom-input no-icon">
                                        <input value={dob} onChange={e => handleOnchange(e) }  type="text" name="dob" placeholder="select date" className="datepicker"/>
                                    </div>*/}
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 10}}>


                                <div className="col s12 m6 l6">

                                </div>
                            </div>

                            {/*<div className="row" style={{ marginTop: 10, display: 'none'}}>

                                <div className="col s12 m4 l4">
                                    <strong>Suburb<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon select">
                                        <select required={true}  onChange={e=> handleOnchange(e)} className="browser-default select2-data-ajax " id="suburbSelection" name="suburb">
                                        </select>
                                    </div>
                                </div>

                                <div className="col s12 m4 l4">
                                    <strong>State<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon" style={{ marginTop: 0}}>
                                        <input disabled={true} value={state} onChange={e => handleOnchange(e) } required  type="text" name="state" placeholder=""/>

                                    </div>
                                </div>

                                <div className="col s12 m4 l4">
                                    <strong>Postcode<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input disabled={true} value={postcode} onChange={e => handleOnchange(e) } required  type="text" name="postcode" placeholder=""/>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 10, display: 'none'}}>
                                <div className="col s12 m12 l12">
                                    <strong>Address<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input value={address} onChange={e => handleOnchange(e) } required type="text" name="address" placeholder="your current address..."/>
                                    </div>
                                </div>
                            </div>*/}

                            <div className="row" style={{ marginTop: 10}}>
                                <div className="col s12 m12 l12">
                                    <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0}} className="green-text"> </p>
                                    <br/>
                                    <button id="submitBtn" className="btn btn-large green">Save changes</button>
                                </div>
                            </div>


                        </form>

                    </div>

                    <div className="col s12 m4 l3 " >
                        {
                            UserSession.getToken() ?
                                <ProfileNavs class={'edit-profile'}/> : ""
                        }
                    </div>



                </div>
            </div>
        </div>
    )

}

export default EditProfilePage;