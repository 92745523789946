import React,{useEffect, useState, useRef} from  'react';
import  '../css/page-users.css';
import Breadcrumbs from '../templates/BreadCrumbs';
import ProfileNavs from '../templates/ProfileNavs';
import {useNavigate, useParams} from 'react-router-dom';
import UserSession from '../../../utils/UserSession';
import Config from '../../../Config';
import axios from "axios/index";
import TempLogo from "../../../images/temp.png";
import homebanner from "../../../images/homebanner-v2.jpg";
import useSession, { UseSessionProvider } from 'react-session-hook';
import { Editor } from '@tinymce/tinymce-react';

function EditListing(props) {

    const navigate = useNavigate();
    const editorRef = useRef(null);
    const session = useSession();
    const {listingId} =  useParams();

    const [business_name, set_business_name] = useState("");
    const [email, set_email] = useState('');
    const [phone_no, set_phone_no] = useState('');
    const [address, set_address] = useState('');
    const [state, set_state] = useState('');
    const [suburb, set_suburb] = useState('');
    const [postcode, set_postcode] = useState('');
    const [category, set_category] = useState('');
    const [website, set_wesbite] = useState('');
    const [country, set_country] = useState('');
    const [abn, set_abn] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoFile, setlogoFile] = useState(null);
    const [banner, setBanner] = useState(null);
    const [bannerFile, setBannerFile] = useState(null);
    const [about, setAbout] = useState('');




    const onEditorChange = (content)=>{
        setAbout(content);
    };

    const handleOnchange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case "shortDescription":
                setAbout(value);
                break;
            case 'name':
                set_business_name(value);
                break;
            case 'email':
                set_email(value);
                break;
            case 'phoneno':
                set_phone_no(value);
                break;
            case 'address':
                set_address(value);
                break;
            case 'state':
                set_state(value);
                break;
            case 'suburb':
                set_suburb(value);
                break;
            case 'country':
                set_country(value);
                break;
            case 'postcode':
                set_postcode(value);
                break;
            case 'website':
                set_wesbite(value);
                break;
            case 'abn':
                set_abn(value);
                break;
            case 'category' :
                set_category(value);
                console.log(category);
                break;
            case 'about':
                setAbout(value);
                break;
            case 'logo' :
                setlogoFile(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = function (event) {

                    window.$('.imagePrev').attr("src" , event.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                break;
            case 'banner' :
                setBannerFile(e.target.files[0]);
                const reader2 = new FileReader();
                reader2.onload = function (event) {

                    window.$('.imagePrev2').attr("src" , event.target.result);
                };
                reader2.readAsDataURL(e.target.files[0]);
                break;
        }
    };

    const countChar = (e) => {
        const len =  e.target.value.length;
        window.$('#charNum').text("("+len+"/1500)");
    };

    useEffect(()=>{

        if(!UserSession.getToken()){
            navigate('/login', {replace: true})
        }else if(UserSession.getUserData().type === 1){
            navigate('/user/profile', {replace: true})
        }else {
             window.loadCategories();
             window.$('#categoryselection').on("change", e=> handleOnchange(e));
             window.$('#stateSelection').on("change", e=> handleOnchange(e));
             window.$('#suburbSelection').on("change", e=> handleOnchange(e));
            window.$("#phoneno").ForceNumericOnly();

            window.$('#suburbSelection').on("select2:selecting", function(e) {

                console.log(e.params.args.data);
                set_state(e.params.args.data.state);
                set_postcode(e.params.args.data.postcode);


            });

             if(UserSession.getUserProfile().length > 0){

                 const url = Config.links.directories+"/"+listingId;
                 const config = {
                     headers: { Authorization: `Bearer ${UserSession.getToken()}` }
                 };
                 axios.get(url,config)
                     .then(function (response) {
                         console.log(response);
                         set_business_name( response.data.directory.name);
                         set_email( response.data.directory.email);
                         set_phone_no( response.data.directory.contact_no);
                         set_address( response.data.directory.address);
                         set_state( response.data.directory.state);
                         set_suburb( response.data.directory.suburb);
                         set_postcode( response.data.directory.postcode);
                         set_category( response.data.directory.category);
                         set_wesbite( response.data.directory.website);
                         set_abn( response.data.directory.abn);
                         setLogo( response.data.directory.logo);
                         setBanner( response.data.directory.banner);
                         set_country( response.data.directory.country);
                         setAbout( response.data.directory.about);
                         window.$('#categoryselection').val([response.data.directory.category]).trigger('change');
                         window.$('#stateSelection').val([response.data.directory.state]).trigger('change');
                         window.$('#suburbSelection').val([response.data.directory.suburb]).trigger('change');
                         const option = window.$('<option selected>'+response.data.directory.suburb+' '+response.data.directory.state+' '+response.data.directory.postcode+'</option>').val(response.data.directory.suburb);
                         window.$('#suburbSelection').append(option).trigger('change');
                     })
                     .catch(function (error) {
                         //console.log(error);
                     });
                 // end get listing data
             }


        }








    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        window.$('#resultTxt').text("");
        const formData = new FormData();
        formData.append('listing_id' , listingId);
        formData.append('user_id' , UserSession.getUserData().id);
        formData.append('name' ,business_name );
        formData.append('email' ,email );
        formData.append('contact_no' , phone_no );
        formData.append('website' ,website );
        formData.append('address' ,address );
        formData.append('state' ,state );
        formData.append('suburb' ,suburb );
        formData.append('postcode' ,postcode );
        formData.append('country' , country );
        formData.append('abn' ,abn );
        formData.append('category' ,category );
        formData.append('about' , about );
        if(logoFile != null){
            formData.append('img' , logoFile );
        }
        if(bannerFile != null){
            formData.append('cover' , bannerFile);
        }

        const config = {
            headers: { Authorization: `Bearer ${UserSession.getToken()}` }
        };
        const url = Config.links.updateListing;

        window.$('#savingLoadingScreen').show();
        axios.post(url, formData,config)
            .then(function (response) {
                //console.log(response);
                window.$('#savingLoadingScreen').hide();
                window.$('#resholderYes').show();
                window.$('#resholderNo').hide();
                window.$('#resultTxtYes').text("Update Successfully.");
            })
            .catch(function (error) {
                window.$('#savingLoadingScreen').hide();
                //console.log(error);
                window.$('#resholderYes').hide();
                window.$('#resholderNo').show();
                window.$('#resultTxtNo').text("Failed. Error occurred when update data.");
            });
    };






    const breacrumbsitems = [
        {name : 'home', link : '/'},
        {name : 'my listings', link : '/user/listings'},
        {name : 'edit', link : '#'},
    ];

    const title = "Edit Listing";

    return(
        <div id="profilepage" style={{ paddingTop: 0, paddingBottom: 50}}>
            <div className="container">
                <div className="row">

                    <div className="col s12 m9 l9  no-padding">
                        <Breadcrumbs items={breacrumbsitems} title={title}/>

                        <form onSubmit={(e) => handleSubmit(e)} style={{ padding: 8, marginTop: 20}}>

                            <div className="row">
                                <div className="col s12 m4 l3 center-align-sm  left-align-lg left-align-md">
                                    <label>
                                        <img className="imagePrev bordered-image rounded" src={ logo != null ? Config.links.asset_link+logo : TempLogo} style={{ width: 165, height : 165 , objectFit : 'cover'}}/>
                                        <input type="file" name="logo" onChange={e => handleOnchange(e)}/>
                                    </label>
                                </div>
                                <div className="col s12 m6 l9">
                                    <div className="row">

                                        <div className="col s12 m12 12">
                                            <strong>Business Name<span className="red-text">*</span></strong>
                                            <div className="custom-input no-icon">
                                                <input value={business_name} onChange={e => handleOnchange(e) } id="name" required type="text" name="name" placeholder=""/>
                                            </div>
                                        </div>

                                        <div className="col s12">
                                            <strong>Business Email Address<span className="red-text">*</span></strong>
                                            <div className="custom-input no-icon">
                                                <input value={email} onChange={e => handleOnchange(e) } required type="email" name="email" placeholder=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="row" style={{ marginTop: 20}}>
                                <div className="col s12 m6 l6">
                                    <strong>Business Phone No.<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input value={phone_no} id="phoneno" onChange={e => handleOnchange(e) } className="phone-format" required type="text" name="phoneno" placeholder=""/>
                                    </div>
                                </div>

                                <div className="col s12 m6 l6">
                                    <strong>Select Category<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon select" style={{ marginTop: 0}}>
                                        <select value={category} onChange={e=> props.handleChange(e)} className="browser-default" id="categoryselection" name="category">

                                        </select>
                                    </div>
                                </div>


                            </div>

                            <div className="row" style={{ marginTop: 10}}>
                                <div className="col s12 m6 l6">
                                    <strong>Street Address</strong>
                                    <div className="custom-input no-icon">
                                        <input value={ address != null && address != "null" ? address : "" } onChange={e => handleOnchange(e) }  type="text" name="address" placeholder=""/>
                                    </div>
                                </div>
                                <div className="col s12 m6 l6">
                                    <strong>Suburb<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon select">
                                        <select required={true}  onChange={e=> handleOnchange(e)} className="browser-default select2-data-ajax " id="suburbSelection" name="suburb">
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="row" style={{ marginTop: 10}}>
                                <div className="col s12 m8 l8">
                                    <strong>Website</strong>
                                    <div className="custom-input no-icon">
                                        <input className="ulrField" value={website != null && website != "null" ? website : ""} onChange={e => handleOnchange(e) }  type="text" name="website" placeholder=""/>
                                    </div>
                                </div>

                                <div className="col s12 m4 l4">
                                    <strong>ABN</strong>
                                    <div className="custom-input no-icon">
                                        <input value={abn != "null" ? abn : ""} onChange={e => handleOnchange(e) }   type="text" name="abn" placeholder=""/>
                                    </div>
                                </div>


                            </div>



                            {
                                UserSession.getUserData().type === 2 ?
                                    <div className="row" style={{ marginTop: 10}}>
                                        <div className="col s12 m12 l12 " style={{ paddingTop: 0, paddingBottom: 50}}>
                                            <strong>Description</strong><br/>
                                            <small>Maximum of 1500 characters</small>
                                            <textarea
                                                onChange={e => handleOnchange(e)}
                                                maxlength="1500"
                                                value={about != null && about != "null" && about != "<p>null</p>" ? about : ""}
                                                onKeyUp={e => countChar(e)}
                                                name="shortDescription"
                                                style={{ border: '2px solid #cccccc',
                                                    borderRadius: 5,
                                                    height: 100,
                                                    width: '100%',
                                                    padding: 10}}></textarea>
                                            <small>Count <span id="charNum">({ about != null && about != "null" && about != "<p>null</p>" ? about.length : "0"}/500)</span></small>
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ marginTop: 10}}>
                                        <div className="col s12 m12 l12">
                                            <strong>Descriptions<span className="red-text">*</span></strong>
                                            <Editor
                                                apiKey='8r59yeqi0ckti09lyqp6werj0iyp7dloff5ez9cxa1otewoj'
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={about}
                                                onEditorChange={content => onEditorChange(content)}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | styleselect | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />


                                        </div>
                                    </div>
                            }



                            <div className="row mt-4">
                                <div className="center col s12 m12 l12 center-align-sm  left-align-lg left-align-md grey lighten-3">

                                    <h4 className="center mt-4">Make the listing stand out!</h4>
                                    <p className="center">Add a photo cover for your company.</p>
                                    <div className="center pb-6">
                                        <label>

                                            <img className="imagePrev2 bordered-image rounded" src={ banner != null ? Config.links.asset_link+banner : homebanner} style={{ width: 300, height : 165 , objectFit : 'cover'}}/><br/>
                                            <input type="file" name="banner" id="imgField2" onChange={e => handleOnchange(e)}/>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row center" style={{ marginTop: 10}}>

                                <div id="resholderYes" className="col s12 m6 l6 push-l3 push-m3 mt-2 mb-2" style={{ display: 'none'}}>
                                    <div className="card-alert  gradient-45deg-green-teal">
                                        <div className="card-content white-text">
                                            <p id="resultTxtYes" style={{ marginTop: 0, marginBottom: 0}} className="white-text"> </p>
                                        </div>

                                    </div>
                                </div>

                                <div id="resholderNo" className="col s12 m6 l6 push-l3 push-m3 mt-2 mb-2" style={{ display: 'none'}}>
                                    <div className="card-alert  gradient-45deg-red-pink">
                                        <div className="card-content white-text">
                                            <p id="resultTxtNo" style={{ marginTop: 0, marginBottom: 0}} className="white-text"> </p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col s12 m12 l12">
                                    <br/>
                                    <button id="submitBtn" className="btn btn-large green">Save Listing</button>
                                </div>
                            </div>


                        </form>

                    </div>

                    <div className="col s12 m4 l3 " >
                        {
                            UserSession.getToken() ?
                                <ProfileNavs class={'my-listing'}/> : ""
                        }
                    </div>



                </div>
            </div>
        </div>
    )

}

export default EditListing;