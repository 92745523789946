import React, {useEffect, useState} from 'react';
import '../css/page-users.css';
import Breadcrumbs from '../templates/BreadCrumbs';
import ProfileNavs from '../templates/ProfileNavs';
import {Link, useNavigate, useParams} from 'react-router-dom';
import UserSession from '../../../utils/UserSession';
import avatar from '../../../images/avatar-15.png';
import axios from "axios/index";
import Config from "../../../Config";
import TempLogo from "../../../images/temp.png";
import useSession, { UseSessionProvider } from 'react-session-hook';

function Listing(props) {
    const session = useSession();
    const navigate = useNavigate();
    let profile = [];
    const {listingId} =  useParams();
    const [listingData , setlistingData] = useState([]);
    const [category , setcategory] = useState([]);
    const [name, setName] = useState("");



    useEffect(() => {


        if(!UserSession.getToken()){
            navigate('/login', {replace: true})
        }else if(UserSession.getUserData().type === 1){
            navigate('/user/profile', {replace: true})
        }else {
            if(UserSession.getUserProfile().length > 0){
                window.initializeRating();

                // Get Listing Data
                const url = Config.links.directories+"/"+listingId;
                const config = {
                    headers: { Authorization: `Bearer ${UserSession.getToken()}` }
                };
                axios.get(url,config)
                    .then(function (response) {
                        console.log(response);
                        setlistingData(response.data.directory);
                        setcategory(response.data.category);
                        setName( response.data.directory.name);
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                // end get listing data


            }else {
                navigate('/user/profile/edit', {replace: true});
            }

        }

    }, []);

    const breacrumbsitems = [
        {name: 'home', link: '/'},
        {name: 'my listings', link: '/user/listings'},
        {name: name, link: '#'},
    ];



    const deleteListing = (e) => {
        window.$('#savingLoadingScreen').show();
        const config = { headers: { Authorization: `Bearer ${UserSession.getToken()}` }};
        axios.delete(Config.links.directories+"/"+listingId, config)
            .then(function (response) {
                //console.log(response);
                window.$('#savingLoadingScreen').hide();
                UserSession.setlisting_space(response.data.listing_space);
                navigate('/user/listings', {replace: true})

            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
            });
    };


    return (
        <div id="profilepage" style={{paddingTop: 0, paddingBottom: 50}}>
            <div className="container">
                <div className="row">

                    <div className="col s12 m9 l9 ">
                        <Breadcrumbs items={breacrumbsitems} title={name}/>


                        <div className="" style={{ margin: 10}}>
                            <div className="row bordered-image  white" style={{ margin: 3, marginTop: 20, padding: 10, paddingTop: 15}}>
                                <div className="col s12 m12 l12">
                                    <div className="row">
                                        <div className="col s12 m4 l3 center-align-sm left-align-lg left-align-md">
                                            <img className="bordered-image rounded" src={listingData.logo != null ? Config.links.asset_link+listingData.logo: TempLogo} style={{ width: 165 ,  height : 165 , objectFit : 'contain'}}/>
                                        </div>
                                        <div className="col s12 m6 l5 ">
                                            <p>Name: {listingData.name}</p>
                                            <p>Business Email: {listingData.email}</p>
                                            <p>Contact No.: {listingData.contact_no}</p>
                                            <p>Category: {listingData.category}</p>
                                        </div>
                                        <div className="col s12 m6 l4 ">
                                            <p>Verified: {listingData.verified === 0 ? "No" : 'Yes'}</p>
                                            <p>Created at: {listingData.created_at}</p>
                                            {listingData.abn != null ?

                                                <p>ABN: {listingData.abn != null && listingData.abn !== "null" ? listingData.abn : ""}</p>
                                                : ''}
                                            {listingData.website != null && listingData.website !== 'null' ?

                                                <p><a href={listingData.website} target="_blank" className="green-text"> <i className="material-icons left" style={{ fontSize: 20, marginRight: 5}}>public</i> Visit Website</a> </p>
                                                : ''}
                                        </div>

                                        <div className="col s12" style={{ marginTop: 10}}>
                                            <div className="col s12 m12 l12 no-padding">
                                                <p style={{ textTransform: "capitalize"}}>
                                                    <i className="material-icons left" style={{ fontSize: 20, marginRight: 5}}>location_on</i>
                                                    Address: {listingData.address} {listingData.suburb}, {listingData.state}, {listingData.country} {listingData.postcode}
                                                </p>
                                            </div>

                                        </div>

                                        <div className="col s12 ">

                                            {
                                                listingData.about != null && listingData.about != '<p>null</p>' && listingData.about != 'null' ?


                                                    <p dangerouslySetInnerHTML={{__html: listingData.about}} />

                                                    : ''
                                            }

                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col s12">
                                    <a href={"/"+category.slug+"/"+listingData.suburb+"-"+listingData.state+ "-"+ listingData.postcode+"/"+listingData.slug+"/"+listingData.id }>
                                        <button className="btn btn-small black"><i className="material-icons left" style={{ fontSize: 20, marginRight: 5}}>visibility</i> View Page</button>
                                    </a>

                                    <a href={"/user/listings/edit/"+listingId }>
                                        <button className="btn btn-small green"><i className="material-icons left" style={{ fontSize: 20, marginRight: 5}}>edit</i> Edit</button>
                                    </a>
                                    &nbsp;
                                    <button className="btn btn-small red" onClick={ e => deleteListing(e)}><i className="material-icons left"  style={{ fontSize: 20, marginRight: 5}}>delete</i> Delete</button>
                                </div>
                            </div>
                        </div>





                    </div>
                    <div className="col s12 m4 l3 ">

                        {
                            UserSession.getToken() ?
                                <ProfileNavs class={'my-listing'}/> : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Listing;