import React, {useEffect, useState, useRef} from 'react';
import 'materialize-stepper/dist/css/mstepper.min.css';
import 'materialize-stepper/dist/js/mstepper';
import './upgrade.js';
import TempLogo from "../../../images/temp.png";
import homebanner from "../../../images/homebanner-v2.jpg";
import { Editor } from '@tinymce/tinymce-react';
import {useNavigate} from "react-router-dom/index";
import $ from 'jquery';
import Config from "../../../Config";
import axios from "axios/index";
import UserSession from "../../../utils/UserSession";

export default function UpgradeAccountPage(props) {

    const [business_name, set_business_name] = useState("");
    const [email, set_email] = useState('');
    const [phone_no, set_phone_no] = useState('');
    const [address, set_address] = useState('');
    const [state, set_state] = useState('');
    const [suburb, set_suburb] = useState('');
    const [postcode, set_postcode] = useState('');
    const [category, set_category] = useState('');
    const [website, set_wesbite] = useState('');
    const [abn, set_abn] = useState('');
    const [country, set_country] = useState('Australia');
    const [logo, setLogo] = useState(null);
    const [banner, setBanner] = useState(null);
    const [about, setAbout] = useState('');
    const [errors, setErrors] = useState([]);
    const [step, set_step] = useState(1);
    const [account_type, set_account_type] = useState(2);
    const [listLink, set_listLink] = useState("");

    const navigate = useNavigate();
    const editorRef = useRef(null);
    const screenheight = window.innerHeight;

    const onEditorChange = (content)=>{
        setAbout(content);
    };

    useEffect(()=>{

        if(UserSession.getUserData().type != 1){
            navigate('/user/profile', {replace: true})
        }


        window.loadCategories();
        window.$("#abn").ForceNumericOnly();
        window.$("#phoneno").ForceNumericOnly();
        window.$('#categoryselection').on("change", e=> handleOnchange(e));
        window.$('#suburbSelection').on("change", e=> handleOnchange(e));


        window.$('#suburbSelection').on("select2:selecting", function(e) {

            console.log(e.params.args.data);
            set_state(e.params.args.data.state);
            set_postcode(e.params.args.data.postcode);


        });

        $("#step-one").fadeOut(100 , 'linear', function() {
            $("#step-one").addClass("hide");
            $("#step-two").fadeIn(100 , 'linear', function() {
                $("#step-two").removeClass("hide");
            });
        });




    }, []);

    const handleOnchange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name) {
            case 'name':

                set_business_name(value);
                break;
            case 'email':
                set_email(value);
                break;
            case 'phoneno':
                set_phone_no(value);
                break;
            case 'address':
                set_address(value);
                break;
            case 'state':
                set_state(value);
                break;
            case 'suburb':
                set_suburb(value);
                break;
            case 'postcode':
                set_postcode(value);
                break;
            case 'country':
                set_country(value);
                break;
            case 'website':
                set_wesbite(value);
                break;
            case 'abn':
                set_abn(value);
                break;
            case 'about':
                setAbout(value);
                break;
            case 'category' :
                set_category(value);

                break;
            case 'account_type':
                set_account_type(value);
                break;
            case 'logo' :
                setLogo(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = function (event) {

                    window.$('.imagePrev').attr("src", event.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                break;
            case 'banner' :
                setBanner(e.target.files[0]);
                const reader2 = new FileReader();
                reader2.onload = function (event) {

                    window.$('.imagePrev2').attr("src", event.target.result);
                };
                reader2.readAsDataURL(e.target.files[0]);
                break;
        }
    };

    const onSubmitForm1 = (e) => {
        e.preventDefault();
        set_step(2);
        $("#step-one").fadeOut(100 , 'linear', function() {
            $("#step-one").addClass("hide");
            $("#step-two").fadeIn(100 , 'linear', function() {
                $("#step-two").removeClass("hide");
            });
        });
    };

    const onSubmitForm2 = (e) => {
        e.preventDefault();

        window.$('#step-two-action-next').attr('disabled' ,  true);
        window.$('#step-two-action-next').text('Loading...');
        const formData = new FormData();
        formData.append('user_id' , UserSession.getUserData().id);
        formData.append('account_type' , account_type );
        formData.append('name' ,business_name );
        formData.append('email' ,email );
        formData.append('contact_no' , phone_no );
        formData.append('website' ,website );
        formData.append('address' ,address );
        formData.append('state' ,state );
        formData.append('postcode' ,postcode );
        formData.append('country' , country );
        formData.append('suburb' ,suburb );
        formData.append('abn' ,abn );
        formData.append('about' ,about );
        formData.append('category' ,category );
        if(logo != null){
            formData.append('img' ,logo );
        }
        if(banner != null){
            formData.append('cover' , banner);
        }


        const config = {
            headers: { Authorization: `Bearer ${UserSession.getToken()}` }
        };
        axios.post(Config.links.createDirectory, formData,config)
            .then(function (response) {
                window.$('#step-two-action-next').attr('disabled' ,  false);
                window.$('#step-two-action-next').text('SUBMIT');

                console.log(response);
                if(response.data.message === "ok"){
                    set_listLink("/user/listings/"+response.data.directory.id);
                    $("#step-two").fadeOut(100 , 'linear', function() {
                        $("#step-two").addClass("hide");
                        $("#step-4").fadeIn(100 , 'linear', function() {
                            $("#step-4").removeClass("hide");
                        });
                    });

                    const dta = UserSession.getUserData();
                    dta.type = 2;
                    UserSession.setUserData(dta);

                }

                if(response.data.message === "failed"){
                    setErrors(response.data.error);
                }



            })
            .catch(function (error) {
                // console.log(error);
                window.$('#step-two-action-next').attr('disabled' ,  false);
                window.$('#step-two-action-next').text('SUBMIT');
            });


    };

    const gotolistings = (e) => {
        navigate(listLink, {replace: true})
    };


    return(
     <div id="upgrade-page" className="">
         <form onSubmit={e => onSubmitForm1(e)} id="step-one-form">
         {/*Step One*/}
         <div className="container " id="step-one">
             <div className="row">
                 <div className="col s12 m12 l12 center-align-sm left-align-md left-align-lg" style={{ paddingTop: 20, paddingBottom: 0}}>

                     <h3>Upgrade to business</h3>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                 </div>
             </div>
             <div className="row">
                 <div className="col s12 m12 l12" style={{ paddingTop: 20, paddingBottom: 50}}>

                     <h5 className="mb-0 center-align-sm left-align-md left-align-lg">Select Plan</h5>
                     <p className="mt-0 center-align-sm left-align-md left-align-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  ea commodo consequat.</p>


                     <div className="row">
                         <div className="col s12 m4 l3 center " style={{  cursor: 'pointer', padding: 20}}>
                             <div className="z-depth-1 white" style={{ padding: '30px 10px'}}>
                                 <h2 className="mb-0 mt-0">$0<span style={{ fontSize: 16}}>/m</span></h2>
                                 <h5 className="mt-0">Basic</h5>
                                 <p className="mb-0">3 Listings </p>
                                 <p  className="mt-0 mb-0">Email Notifications</p>
                                 <p  className="mt-0">&nbsp;</p>
                                 <label>
                                     <input checked={true} value={2} onChange={e => handleOnchange(e)} required={true} name="account_type" type="radio" />
                                     <span>Select</span>
                                 </label>
                             </div>
                         </div>

                         <div className="col s12 m4 l3 center" style={{ cursor: 'pointer', paddingBottom: 10}}>
                             <h2 className="mb-0 grey-text">$$<span style={{ fontSize: 16}}>/m</span></h2>
                             <h5 className="mt-0 grey-text">Standard</h5>
                             <p className="mb-0 grey-text">10 Listings </p>
                             <p  className="mt-0 mb-0 grey-text">Email Notifications</p>
                             <p  className="mt-0 grey-text">SMS Notifications</p>
                             <label>
                                 <input disabled={true}  value={3} required={true} onChange={e => handleOnchange(e)} name="account_type" type="radio" />
                                 <span>Coming soon</span>
                             </label>
                         </div>
                         <div className="col s12 m4 l3 center" style={{ cursor: 'pointer', paddingBottom: 10}}>
                             <h2 className="mb-0 grey-text">$$<span style={{ fontSize: 16}}>/m</span></h2>
                             <h5 className="mt-0 grey-text">Premium</h5>
                             <p className="mb-0 grey-text">20 Listings </p>
                             <p  className="mt-0 mb-0 grey-text">Email Notifications</p>
                             <p  className="mt-0 grey-text">SMS Notifications</p>
                             <label>
                                 <input disabled={true} value={4} required={true} onChange={e => handleOnchange(e)} name="account_type" type="radio" />
                                 <span>Coming soon</span>
                             </label>
                         </div>
                     </div>

                     <div className="mt-5 center-align-sm left-align-lg left-align-md">
                         <button type="submit"  id="step-one-action-next" className="btn btn-flat white-text btn-large green"><i className="material-icons right ">arrow_forward</i>CONTINUE</button>
                     </div>

                 </div>
             </div>
         </div>
         </form>

         <form onSubmit={e => onSubmitForm2(e)} id="step-two-form">
         {/*Step Two*/}
         <div className="container hide" id="step-two">
             <div className="row">
                 <div className="col s12 m12 l12" style={{ paddingTop: 20, paddingBottom: 0}}>

                     <h3>Business Details</h3>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                 </div>
             </div>
             <div className="row " style={{ paddingTop: 20, paddingBottom: 10}}>

                 <div className="col s12 m6 l6 ">
                     <div className="row">
                         <div className="col s12 m12 12 mt-2">
                             <strong>Business Name<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon">
                                 <input  id="name" onChange={e => handleOnchange(e)} required type="text" name="name" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m12 l12 mt-2" >
                             <strong>Please select the business's primary industry<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon select" >
                                 <select required={true}  className="browser-default" id="categoryselection" name="category">

                                 </select>
                             </div>
                         </div>

                     </div>

                     <div className="row">
                         <div className="col s12 m6 16 mt-2">
                             <strong>Street Address</strong>
                             <div className="custom-input no-icon">
                                 <input  id="name" required type="text" onChange={e => handleOnchange(e)} name="address" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m6 l6 mt-2">
                             <strong>Suburb<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon select">
                                 <select required={true}  onChange={e=> handleOnchange(e)} className="browser-default select2-data-ajax " id="suburbSelection" name="suburb">
                                 </select>
                             </div>
                         </div>
                     </div>

                 </div>

                 <div className="col s12 m6 l6">

                     <div className="row">
                         <div className="col s12 m12 112 mt-2">
                             <strong>Business Business Phone No.<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon">
                                 <input  id="phoneno" required type="text" onChange={e => handleOnchange(e)} name="phoneno" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m12 l12 mt-2">
                             <strong>Business Email Address<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon">
                                 <input  required type="email" onChange={e => handleOnchange(e)} name="email" placeholder=""/>
                             </div>
                         </div>
                     </div>


                     <div className="row">
                         <div className="col s12 m6 16 mt-1">
                             <strong>Business Website</strong>
                             <div className="custom-input no-icon">
                                 <input className="ulrField"  id="website"  type="text" onChange={e => handleOnchange(e)} name="website" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m6 l6 mt-1">
                             <strong>ABN</strong>
                             <div className="custom-input no-icon">
                                 <input  type="text" onChange={e => handleOnchange(e)} id="abn" name="abn" placeholder=""/>
                             </div>
                         </div>
                     </div>



                 </div>



             </div>

             <div className="row">
                 <div className="col s12 m12 l12">
                     <span>Descriptions</span>
                     <Editor
                         apiKey='8r59yeqi0ckti09lyqp6werj0iyp7dloff5ez9cxa1otewoj'
                         onInit={(evt, editor) => editorRef.current = editor}
                         initialValue=""
                         onEditorChange={content => onEditorChange(content)}
                         init={{
                             height: 500,
                             menubar: false,
                             plugins: [
                                 'advlist autolink lists link image charmap print preview anchor',
                                 'searchreplace visualblocks code fullscreen',
                                 'insertdatetime media table paste code help wordcount'
                             ],
                             toolbar: 'undo redo | styleselect | formatselect | ' +
                             'bold italic backcolor | alignleft aligncenter ' +
                             'alignright alignjustify | bullist numlist outdent indent | ' +
                             'removeformat | help',
                             content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                         }}
                     />
                 </div>
             </div>

             <div className="row" style={{ marginTop: 20}}>

                 <div className="col s12">
                     <h3 className="center-align-sm center-align-md center-align-lg">Make the listing stand out!</h3>
                     <p className="mb-0 center-align-sm center-align-md center-align-lg">Add a logo and  photo cover for your company.</p>

                 </div>

                 <div className="col s12 m6 l6 center mt-3">
                     <h5>Company Logo</h5>
                     <label>
                         <img className="imagePrev bordered-image rounded white" src={ TempLogo} style={{ width: '100%', height : 300 , objectFit : 'contain'}}/>
                         <br/>
                         <input type="file" name="logo" id="imgField" onChange={e => handleOnchange(e)}/>
                     </label>
                 </div>

                 <div className="col s12 m6 l6 center mt-3">
                     <h5>Banner Photo</h5>
                     <label>
                         <img className="imagePrev2 bordered-image rounded" src={ homebanner} style={{ width: '100%', height : 300 , objectFit : 'cover'}}/><br/>
                         <input type="file" name="banner" id="imgField2" onChange={e => handleOnchange(e)}/>
                     </label>
                 </div>

             </div>

             <div className="row  mt-5">
                 <div className="col s12 m12 l12 mt-1 center">

                     <label>
                         <input required={true}   type="checkbox"  className="filled-in"  />
                         <span>I agree to <a href="/terms-of-use" target="_blank" className="green-text">Terms of Use</a> and <a href="/terms-and-conditions" target="_blank" className="green-text">Terms &  Conditions.</a></span>
                     </label>
                 </div>
             </div>

             <div className="row mt-3"  style={{ paddingBottom: 50}}>

                 <div className="col s12 m6 push-l3 l6 push-m3">
                     {Object.keys(errors).map((key, i) => (

                         Object.keys(errors[key]).map((key2, j) => (

                             <div key={i} className="card-alert  gradient-45deg-red-pink">
                                 <div className="card-content white-text">
                                     <p className="mt-0 mb-0">
                                         <i className="material-icons">error</i> ERROR : {errors[key][key2]}</p>
                                 </div>

                             </div>

                         ))
                     ))}
                 </div>


                 <div className="col s12 m12 112 mt-4 center-align-sm center">

                     {/*<button type="button" id="step-two-action-back" className=" btn-flat black-text btn-large no-padding mr-5 transparent"><i className="material-icons left ">arrow_back</i>BACK</button>*/}
                     <button  type="submit" id="step-two-action-next" className="btn btn-flat white-text btn-large green ">SUBMIT</button>


                 </div>
             </div>


         </div>
         </form>


         <div className="container  hide center " id="step-4" style={{ minHeight: screenheight - 195}}>

             <div style={{ marginTop: 100}}>
                 <h2 className="center mb-0" >Congratulations!</h2><br/>
                 <p className="mt-0">You have successfully made a business account and upload your first listing.</p>
                 <button className="btn btn-large green" onClick={e => gotolistings(e)}>Click here to check your listing</button>
             </div>

         </div>


     </div>
    )

}