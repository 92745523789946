import React,{useState} from 'react';
import {Link} from "react-router-dom";
import logoBlack from '../../images/logo-hd-black.png'
import axios from "axios/index";
import config from "../../Config";
import UserSession from "../../utils/UserSession";


export default function ForgotPassword(props) {


    const [email, setEmail] = useState("");

    const handleOnChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'email':
                setEmail(value);
                break;
        }

    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        window.$('#submitBtn').attr('disabled' ,  true);
        window.$('#submitBtn').text('Loading...');

        axios.post(config.links.forgot_password, {
            email: email
        })
            .then(function (response) {
                //console.log(response);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('Send Password Reset Link');
                window.$('#resultTxt').text(response.data.message);
            })
            .catch(function (error) {
                //console.log(error);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('Send Password Reset Link');
            });

    };

    return(
        <div className="container  valign-wrapper" id="register-page" style={{ height: '100vh',   paddingTop: 50, paddingBottom: 50} }>

            <div className="row">
                <div className="col s12 m12 l10 push-l1   z-depth-5 register-form-main-holder">


                    <div className="row equal-height">
                        <div className="col s12 m6 l6  hide-on-small-and-down center-align-sm left-align-lg" style={{ padding: 40, paddingTop: 60}}>
                            <Link to='/'><img src={logoBlack} style={{ width: 150}}/> </Link>
                            <p>Australia's largest collection of trusted local businesses reviewed by you.</p>

                        </div>
                        <div className="col s12 m6 l6  white padding-4 min-height-100" style={{ paddingTop: 30, paddingBottom: 70}}  >

                            <h4 style={{ marginTop: 0, marginBottom: 1}}>Forgot Password? </h4>
                            <p style={{marginTop: 5, marginBottom: 0, fontWeight: 400, color: '#999'}}>Don't have an account? <Link to="/user/register" className="green-text">Register here</Link></p>

                            <form style={{ marginTop: 30}} onSubmit={e => handleOnSubmit(e)}>

                                <strong>Email Address</strong>
                                <div className="custom-input">
                                    <i className="material-icons dp48">mail_outline</i>
                                    <input required type="email" onChange={e => handleOnChange(e)} name="email" placeholder="youremail@domain.com..."/>
                                </div>
                                 <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0}} className="red-text center"> </p>
                                <br/>
                                <button id="submitBtn" className="btn btn-large green width-100">Send password reset link</button>
                                <br/>
                                <div className="center ">
                                    <p>Copyright © 2022 Going Local</p>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}