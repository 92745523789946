import React, { useEffect, useState} from 'react';
import SearchForm from "../templates/SearchForm.Component";
import {useNavigate, useParams} from "react-router-dom/index";
import ListingItemVersion1 from '../templates/ListingItem.version1';
import webIcon from '../../../images/worldwide.png';
import Config from "../../../Config";
import axios from "axios/index";
import mapicon from '../../../images/map.png';
import {
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ReCaptchaComponent from '../templates/YourReCaptchaComponentHidden';

export default function SearchResults(props) {

    const navigate = useNavigate();
    const [slocation, setLocation] = useState("");
    const [companyNname, setcompanyNname] = useState("");

    const [listings, setlistings] = useState([]);
    const [isSearching, setisSearching] = useState(false);

    const {location, name} = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const  searchlocation = queryParams.get("location");
    const  searchbusinessName = queryParams.get("company_name");

    let {c_location} = useParams();
    let {c_name} = useParams();
    let {cat_name} = useParams();



    useEffect(()=>{

        if(cat_name != null){
            window.$("#businessNameField").val(cat_name);
        }else {
            window.$("#businessNameField").val(c_name);

            if(c_location != null){
                window.$("#locationField").val(c_location.replace(/-/g, ' '));
            }
        }



        setisSearching(true);
        axios.get(Config.links.search_company, {params: {suburb: c_location, name: c_name}})
            .then((response) => {
                setisSearching(false);
                setlistings(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                setisSearching(false);
                console.log(error);
            })



    }, [c_name]);


    const handleOnchange = e =>{
        const { name, value } = e.target;
        if(name === "location"){
            setLocation(value);
            alert(value);
        }

        if(name === "company_name"){
            setcompanyNname(value);
        }
    };


    const handleOnSubmit = (e)=>{
         e.preventDefault();
         const  locationField = window.$('#locationField').val();
         const  businessNameField = window.$('#businessNameField').val();
         if(locationField === ""){
             window.location.href = '/find/'+businessNameField;
         }else {
             window.location.href = '/find/'+locationField.replace(/\s+/g, '-')+'/'+businessNameField;
         }

    };

    function onAddBusinessClick() {
        navigate("/user/listings/add");
    }

    return(
        <div id="serachResultpage" style={{ paddingTop: 0, minHeight: '100vh'}}>


            <GoogleReCaptchaProvider
                reCaptchaKey="6LfSB8ofAAAAACTHI8ZZQJSfgM_hlPe7S5lGXjMz">
                <ReCaptchaComponent/>
            </GoogleReCaptchaProvider>

            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="col s12 breadcrumb-link-holders " >
                            <a  className="breadcrumb">Home</a>
                            {
                                 c_name != null ?
                                    <a  className="breadcrumb">{c_name.replace(/-/g, ' ')}  { c_location != null &&  c_location.trim() !== '' ? 'in '+c_location.replace(/-/g, ' ') : ''}</a>
                                    :
                                    <a  className="breadcrumb">Search</a>
                            }

                        </div>
                    </div>
                </div>
            </div>
           <div className="container" >
               <div className="row">
                   <div className="col s12 m12 l8" >

                       <form id="searchpage" onSubmit={ e => handleOnSubmit(e)}  >
                           <SearchForm location={location} name={c_name} handleChange={e => handleOnchange(e)} />
                       </form>

                       {
                            c_name != null ?
                               <p className=" center-align-sm left-align-md">{listings.length} <span style={{ fontWeight: 900}}>'{c_name.replace(/-/g, ' ')}'</span>  found  <span style={{ fontWeight: 900}}>{c_location != null &&  c_location.trim() !== '' ? 'in '+c_location.replace(/-/g, ' ') : ''}</span></p>

                               :
                               ""
                       }








                       {
                           listings.length > 0 ?

                           Array(listings.length).fill(1).map((el, i) =>
                               <ListingItemVersion1 key={i} data={listings[i]}/>
                           )

                               :

                               <h2>No data found.</h2>
                       }



                      {/* <div className="container" style={{ marginTop: 50, marginBottom: 100, padding:0, paddingBottom: 50}}>
                           <div className="row grey lighten-2 add-business-card-link-holder" style={{maxWidth: 1000, margin: 'auto', padding: 20, borderRadius: 5}}>
                               <div className="col s12 m2 l2 center-align">
                                   <img src={mapicon} width={80}/>
                               </div>
                               <div className="col s12 m6 l5 left-align-lg center-align-sm">
                                   <h5>Couldn't find the business?</h5>
                                   <p>No worries, let's add it now</p>
                               </div>
                               <div className="col s12 m4 l5 right-align-md right-align-lg center-align-sm" style={{ paddingTop: 18}}>
                                   <form onSubmit={ () => onAddBusinessClick()}>
                                       <button type="submit" className="btn btn-large waves-effect waves-light gradient-45deg-cyan-light-green btn-flat white-text "><i className="material-icons left">add</i> ADD BUSINESS</button>
                                   </form>
                               </div>
                           </div>
                       </div>*/}






                   </div>

















                   <div className="col s12 m12 l4  serachResultpage-left-panel">


                       {/*{
                           searchlocation != null && searchbusinessName != null ?
                               <p>{searchbusinessName} in {searchlocation} - <span className="green-text">map view</span> </p>
                                : ""
                       }

                       <div className="map-view">

                            <div style={{minHeight: 300}} className="valign-wrapper ">
                                <div className="align-items-center" style={{margin: 'auto'}}>
                                    <a className="btn-small z-depth-0 green mb-1 waves-effect">
                                        <i className="material-icons left">map</i>
                                        Explore using map view
                                    </a>
                                </div>
                            </div>
                        </div>*/}


                       {/*<div className="card green z-depth-0">
                           <div className="card-content white-text">
                               <span className="card-title">Grow your business 24/7</span>
                               <p>
                                   A website is there when you can’t be.
                                   The easy, affordable way to grow your business in this year.
                               </p>
                           </div>
                           <div className="card-action">
                               <a href="/user/listings/add" className="lime-text text-accent-1">Get Started Now</a>
                           </div>
                       </div>
*/}

                   </div>
               </div>
           </div>




        </div>
    )
}