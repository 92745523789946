import React, {useState, useEffect, useCallback} from 'react';
import SearchForm from '../templates/SearchForm.Component';
import {useNavigate} from 'react-router-dom';
import sect2Imgleft from '../../../images/2-a-l.jpg';
import sect2Imgright from '../../../images/home-RIGHT-IMG.jpg';
import mpart1 from '../../../images/mp-art-1.png';
import mpart2 from '../../../images/mp-2.png';

import Countdown from "react-countdown";
import UserSession from "../../../utils/UserSession";
import axios from "axios/index";
import config from "../../../Config";
import {
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ReCaptchaComponent from '../templates/YourReCaptchaComponent';
import $ from 'jquery';


function HomePage(props) {

    let navigate = useNavigate();
    const [location, setLocation] = useState("");
    const [companyNname, setcompanyNname] = useState("");
    const [successSubscribing, setsuccessSubscribing] = useState(false);

    const handleOnSubmit = (e)=>{
        e.preventDefault();
        const  locationField = window.$('#locationField').val();
        const  businessNameField = window.$('#businessNameField').val();
        if(locationField === ""){
            window.location.href = '/find/'+businessNameField;
        }else {
            window.location.href = '/find/'+locationField.replace(/\s+/g, '-')+'/'+businessNameField;
        }

    };

    const handleOnchange = e => {
        const {name, value} = e.target;
        if (name === "location") {
            setLocation(value);
        }

        if (name === "company_name") {
            setcompanyNname(value);
        }
    };

    const subscribeEmail = (e) => {
        e.preventDefault();
        //console.log(UserSession.getCaptchaToken());
        //return;

        if (UserSession.getCaptchaToken()) {
            window.$('#subscribeBTN').attr('disabled', true);
            window.$('#subscribeBTN').text('Loading...');
            const formData = new FormData();
            formData.append('token', UserSession.getCaptchaToken());
            formData.append('email', window.$('#subscribeEmail').val());
            axios.post(config.links.subscribeEmail, formData)
                .then(function (response) {
                    console.log(response);
                    if (response.data.message === 'ok') {
                        setsuccessSubscribing(true);
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                    window.$('#subscribeBTN').attr('disabled', false);
                    window.$('#subscribeBTN').text('SUBSCRIBE');
                });
        } else {
            alert('Captcha Error. Please Reload page.')
        }


    };

    const seemorehandler = (e , target) =>{
        e.preventDefault();



        const starget = $("#"+target);
        if($(starget).is(':visible')){
            $(starget).hide();
            $("."+target).text("View more")
        }else {
            $(starget).show();
            $("."+target).text("View less")
        }


    };

    return (
        <div>


            <div className="main-search-holder">
                <div className="main-search-holder-wrapper">
                    <div className="search-holder">
                        <div className="container ">
                            <div className="row" style={{paddingTop: 230}}>
                                <div className="col s12">

                                    <h5 className="white-text center">
                                        SHOP <span className="green-text">LOCAL</span>. EAT <span className="green-text">LOCAL</span>. HIRE <span className="green-text">LOCAL</span>. ENJOY <span className="green-text">LOCAL</span>.
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className=" container ">
                            <form  onSubmit={ e => handleOnSubmit(e)}>
                                <SearchForm handleChange={e => handleOnchange(e)}/>
                            </form>
                        </div>

                        <div className="container" style={{marginTop: 20, paddingBottom: 230}}>
                            <div className="row">
                                <div className="col center center-align s12 white-text">
                                    <p>Going Local™ - Australia's Premium Business Directory where we connect you with the locals!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">

                <div className="row" style={{paddingTop: 50}}>
                    <div className="col s12 center">
                        <h3 className="center black-text">
                            ARE YOU LOOKING FOR MORE EXPOSURE FOR YOUR BUSINESS?
                        </h3>
                        <h5 className="center black-text">
                            <br/>
                            SIGN UP NOW WITH GOING LOCAL FOR <span className="green-text">FREE!</span>
                        </h5>
                        <p style={{maxWidth: 800, margin: 'auto', marginTop: 30, marginBottom: 30}}
                           className="center black-text">
                            Going Local™ isn't just another listing service! We are creating an ecosystem where the local community comes together to share what they really love most. You will be able to search for local businesses, create and view local reviews and the ability to promote products, services, offers and events through Going Local™ Marketplace and Going Local™ Magazine – (both launching in September 2022).
                        </p>
                        <a href="/register/business">
                            <button className="btn btn-large green ">SIGN UP NOW</button>
                        </a>
                    </div>
                </div>

            </div>

            <div className="container-l" style={{paddingTop: 50, paddingBottom: 50}}>

                <div className="row">
                    <div className="col s12 center pb-1">
                        <h5 className="center black-text">
                            This is how it works for you and your business:
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6 l6 pb-7"
                         style={{backgroundImage: 'url(' + sect2Imgleft + ')', backgroundPosition: 'center top'}}>
                        <div className="center-align-sm left-align-md left-align-lg"
                             style={{margin: '300px auto 0px auto ', marginTop: 300, maxWidth: 500}}>
                            <div className="white padding-4 black-text center-align-sm left-align-md left-align-lg">
                                <h5>Generate More Exposure</h5>
                                <p>By registering your business on Going Local
                                    your business will be listed on our local directory.
                                    By generating local traffic online we can connect you
                                    to your local community looking for businesses just
                                    like yours!</p>
                            </div>
                            <a href="/register/business">
                                <button className="btn btn-large green mt-5">SIGN UP NOW</button>
                            </a>
                        </div>


                    </div>
                    <div className="col s12 m6 l6 pb-7"
                         style={{backgroundImage: 'url(' + sect2Imgright + ')', backgroundPosition: 'top '}}>
                        <div className="center-align-sm left-align-md left-align-lg"
                             style={{margin: '300px auto 0px auto ', marginTop: 300, maxWidth: 500}}>
                            <div className="white padding-4 black-text center-align-sm left-align-md left-align-lg">
                                <h5>Get the latest updates</h5>
                                <p>Receive on-going news, promotions and daily insights with our monthly newsletter.<br/><br/><br/></p>
                            </div>
                            <a href="/register/business">
                                <button className="btn btn-large green mt-5">SIGN UP NOW</button>
                            </a>
                        </div>

                    </div>
                </div>
            </div>


            <div className="container" style={{paddingTop: 10, paddingBottom: 50}}>
                <div className="row">
                    <div className="col s12 m6 l4 pt-2 pb-2 center">
                        <img src={mpart1}/>
                    </div>
                    <div className="col s12 m6 l8 pt-2 pb-2 center-align-sm left-align-lg left-align-md">
                        <h5>THE MARKETPLACE</h5>
                        <div>
                            A place where buyers and sellers connect. If you promote products,
                            services, have an upcoming event or even a promotion -
                            marketplace is where you want to promote it.
                            <br/>
                            <br/>
                            Marketplace will be launching in September 2022!

                            <h1>
                                <Countdown date={new Date("2022-08-01")}/>
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6 l4 pt-2 pb-2 center">
                        <img src={mpart2}/>
                    </div>
                    <div className="col s12 m6 l8 pt-2 pb-2 center-align-sm left-align-lg left-align-md">
                        <h5>GOING<span className="green-text">LOCAL</span>™ MAGAZINE</h5>
                        <div>
                            Our publication Going Local Magazine will be launched in September 2022 which
                            will be promoting local business offers online and also
                            through printed editions which will be distributed to your local marketplace.

                            <h1>
                                <Countdown date={new Date("2022-08-01")}/>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>


            <div className="black">
                <div className="container " style={{paddingTop: 50, paddingBottom: 50}}>
                    <div className="row">
                        <div className="col s12 center">
                            <h5 className="white-text">BUSINESS NEWSLETTER</h5>
                            <p className="white-text" style={{maxWidth: 800, margin: 'auto'}}>
                                Subscribe to our Newsletter for regular Going Local updates with hints and tips on
                                promoting your business,
                                local business case studies, special events and best of all you receive our latest
                                offers and deals.
                            </p>
                            <div style={{maxWidth: 800, margin: 'auto'}}>

                                {
                                    successSubscribing ?
                                        <div>
                                            <p className="green-text">Thank you for subscribing.<br/>You will now
                                                receive Going Local updates.</p>
                                        </div>
                                        :
                                        <form onSubmit={e => subscribeEmail(e)}>
                                            <div className="row" style={{marginTop: 25}}>
                                                <div className="col s12 m9 l9">
                                                    <div className="custom-input ">
                                                        <i className="material-icons dp48">mail_outline</i>
                                                        <input id="subscribeEmail" className="white" required
                                                               type="email" name="email"
                                                               placeholder="Enter your email address here..."/>
                                                    </div>
                                                </div>
                                                <div className="col s12 m3 l3 ">
                                                    <GoogleReCaptchaProvider
                                                        reCaptchaKey="6LfSB8ofAAAAACTHI8ZZQJSfgM_hlPe7S5lGXjMz">
                                                        <ReCaptchaComponent/>
                                                    </GoogleReCaptchaProvider>
                                                </div>
                                            </div>
                                        </form>

                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" style={{paddingTop: 50, paddingBottom: 50}}  >

                <div className="row " >
                    <div className="col s12 center">
                        <h2>Top 100 local categories for Australia</h2>
                    </div>
                </div>



                <div className="row" >
                    <div className="col s12 m3 l3 mt-3">



                        <div className="top100Cats">
                            <p><a href="/find/categories/accommodation"><i className="material-icons left">chevron_right</i> Accommodation</a></p>
                            <p><a href="/find/categories/accountants"><i className="material-icons left">chevron_right</i> Accountants</a></p>
                            <p><a href="/find/categories/acupuncture"><i className="material-icons left">chevron_right</i> Acupuncture</a></p>
                            <p><a href="/find/categories/aged-care-services"><i className="material-icons left">chevron_right</i> Aged Care Services</a></p>
                            <p><a href="/find/categories/airconditioning-automotive"><i className="material-icons left">chevron_right</i> Airconditioning - Automotive</a></p>
                            <p><a href="/find/categories/airconditioning - commercial"><i className="material-icons left">chevron_right</i> Airconditioning - Commercial</a></p>
                            <div id="vm001" style={{ display: 'none'}}>
                                <p><a href="/find/categories/aircondotioning-residential"><i className="material-icons left">chevron_right</i> Aircondotioning - Residential</a></p>
                                <p><a href="/find/categories/airport-shuttle-services"><i className="material-icons left">chevron_right</i> Airport Shuttle Services</a></p>
                                <p><a href="/find/categories/alternative-natural-therapies"><i className="material-icons left">chevron_right</i> Alternative Natural Therapies</a></p>
                                <p><a href="/find/categories/amusement-Centres"><i className="material-icons left">chevron_right</i> Amusement Centres</a></p>
                                <p><a href="/find/categories/animals-pet-services"><i className="material-icons left">chevron_right</i> Animals & Pet Services</a></p>
                                <p><a href="/find/categories/aquariums-supplies"><i className="material-icons left">chevron_right</i> Aquariums & Supplies</a></p>
                            </div>
                            <a href="#"  className="vmction vm001" onClick={e => seemorehandler(e, 'vm001')}
                               data-target="vm001">View more</a>
                        </div>
                    </div>
                    <div className="col s12 m3 l3 mt-3">
                        <div className="top100Cats">
                            <p><a href="/find/categories/baby-products"><i className="material-icons left">chevron_right</i> Baby Products</a></p>
                            <p><a href="/find/categories/baby-sitters"><i className="material-icons left">chevron_right</i> Baby Sitters</a></p>
                            <p><a href="/find/categories/bakery"><i className="material-icons left">chevron_right</i> Bakery</a></p>
                            <p><a href="/find/categories/balustrading"><i className="material-icons left">chevron_right</i> Balustrading</a></p>
                            <p><a href="/find/categories/banking-financial-services"><i className="material-icons left">chevron_right</i> Banking & Financial Services</a></p>
                            <p><a href="/find/categories/barbers"><i className="material-icons left">chevron_right</i> Barbers</a></p>
                            <div id="vm002" style={{ display: 'none'}}>
                                <p><a href="/find/categories/bathroom-renovations"><i className="material-icons left">chevron_right</i> Bathroom Renovations</a></p>
                                <p><a href="/find/categories/batteries-automotive"><i className="material-icons left">chevron_right</i> Batteries Automotive</a></p>
                                <p><a href="/find/categories/beauty-salons"><i className="material-icons left">chevron_right</i> Beauty Salons</a></p>
                                <p><a href="/find/categories/beds-bedding"><i className="material-icons left">chevron_right</i> Beds & Bedding</a></p>
                                <p><a href="/find/categories/bicycles-accessories-repairs"><i className="material-icons left">chevron_right</i> Bicycles - Accessories & Repairs</a></p>
                                <p><a href="/find/categories/blinds"><i className="material-icons left">chevron_right</i> Blinds</a></p>
                            </div>
                            <a href="#"  className="vmction vm002" onClick={e => seemorehandler(e, 'vm002')}
                               data-target="vm002">View more</a>
                        </div>
                    </div>
                    <div className="col s12 m3 l3 mt-3">
                        <div className="top100Cats">
                            <p><a href="/find/categories/books-retail"><i className="material-icons left">chevron_right</i> Books - Retail</a></p>
                            <p><a href="/find/categories/boutiques"><i className="material-icons left">chevron_right</i> Boutiques</a></p>
                            <p><a href="/find/categories/bowling-clubs"><i className="material-icons left">chevron_right</i> Bowling Clubs</a></p>
                            <p><a href="/find/categories/brakes-clutch-services"><i className="material-icons left">chevron_right</i> Brakes & Clutch Services</a></p>
                            <p><a href="/find/categories/brick-cleaning"><i className="material-icons left">chevron_right</i> Brick Cleaning</a></p>
                            <p><a href="/find/categories/bridal-wear-hire"><i className="material-icons left">chevron_right</i> Bridal Wear - Hire</a></p>
                            <div id="vm003" style={{ display: 'none'}}>
                                <p><a href="/find/categories/building-construction"><i className="material-icons left">chevron_right</i> Building & Construction</a></p>
                                <p><a href="/find/categories/cabinet-makers"><i className="material-icons left">chevron_right</i>  Cabinet Makers</a></p>
                                <p><a href="/find/categories/cafes"><i className="material-icons left">chevron_right</i> Cafes</a></p>
                                <p><a href="/find/categories/cake-decoration-supplies"><i className="material-icons left">chevron_right</i> Cake Decoration Supplies</a></p>
                                <p><a href="/find/categories/car-detailing"><i className="material-icons left">chevron_right</i> Car Detailing</a></p>
                                <p><a href="/find/categories/car-hire"><i className="material-icons left">chevron_right</i> Car Hire</a></p>
                            </div>
                            <a href="#"  className="vmction vm003" onClick={e => seemorehandler(e, 'vm003')}
                               data-target="vm002">View more</a>
                        </div>
                    </div>
                    <div className="col s12 m3 l3 mt-3">
                        <div className="top100Cats">
                            <p><a href="/find/categories/car-parking"><i className="material-icons left">chevron_right</i> Car Parking</a></p>
                            <p><a href="/find/categories/carpenters"><i className="material-icons left">chevron_right</i> Carpenters</a></p>
                            <p><a href="/find/categories/carpet-flooring"><i className="material-icons left">chevron_right</i> Carpet & Flooring</a></p>
                            <p><a href="/find/categories/carpet-cleaning"><i className="material-icons left">chevron_right</i> Carpet Cleaning</a></p>
                            <p><a href="/find/categories/carports-pergolas"><i className="material-icons left">chevron_right</i> Carports & Pergolas</a></p>
                            <p><a href="/find/categories/cartridges-ink-toner"><i className="material-icons left">chevron_right</i> Cartridges - Ink & Toner</a></p>
                            <div id="vm004" style={{ display: 'none'}}>
                                <p><a href="/find/categories/catering-food"><i className="material-icons left">chevron_right</i> Catering & Food</a></p>
                                <p><a href="/find/categories/celebrant-cervices"><i className="material-icons left">chevron_right</i> Celebrant Services</a></p>
                                <p><a href="/find/categories/childcare-centres"><i className="material-icons left">chevron_right</i> Childcare Centres</a></p>
                                <p><a href="/find/categories/children-parties"><i className="material-icons left">chevron_right</i> Children Parties</a></p>
                                <p><a href="/find/categories/chiropractors"><i className="material-icons left">chevron_right</i> Chiropractors</a></p>
                                <p><a href="/find/categories/churches-mosques-temples"><i className="material-icons left">chevron_right</i> Churches, Mosques & Temples</a></p>
                            </div>
                            <a href="#"  className="vmction vm004" onClick={e => seemorehandler(e, 'vm004')}
                               data-target="vm002">View more</a>
                        </div>
                    </div>

                </div>


                <div className="row ">
                    <div className="col s12 m3 l3 mt-3">

                        <div className="top100Cats">
                            <p><a href="/find/categories/Cinemas"><i className="material-icons left">chevron_right</i> Cinemas</a></p>
                            <p><a href="/find/categories/City-Councils"><i className="material-icons left">chevron_right</i> City Councils</a></p>
                            <p><a href="/find/categories/Cleaning-Contractors"><i className="material-icons left">chevron_right</i> Cleaning Contractors</a></p>
                            <p><a href="/find/categories/Cleaning-Homes"><i className="material-icons left">chevron_right</i> Cleaning - Homes</a></p>
                            <p><a href="/find/categories/Cleaning-Products-Supplies"><i className="material-icons left">chevron_right</i> Cleaning Products & Supplies</a></p>
                            <p><a href="/find/categories/Clothing"><i className="material-icons left">chevron_right</i> Clothing</a></p>
                            <div id="vm005" style={{ display: 'none'}}>
                                <p><a href="/find/categories/Clothing-Alterations"><i className="material-icons left">chevron_right</i> Clothing Alterations</a></p>
                                <p><a href="/find/categories/Clubs-Pubs"><i className="material-icons left">chevron_right</i> Clubs & Pubs</a></p>
                                <p><a href="/find/categories/Computer-Equipment-Repairs"><i className="material-icons left">chevron_right</i> Computer Equipment & Repairs</a></p>
                                <p><a href="/find/categories/Concrete-Contractors"><i className="material-icons left">chevron_right</i> Concrete Contractors</a></p>
                                <p><a href="/find/categories/Concrete-Sawing"><i className="material-icons left">chevron_right</i> Concrete Sawing</a></p>
                                <p><a href="/find/categories/Conveyancing-Services"><i className="material-icons left">chevron_right</i>  Conveyancing Services</a></p>
                            </div>
                            <a href="#"  className="vmction vm005" onClick={e => seemorehandler(e, 'vm005')}
                               data-target="vm002">View more</a>
                        </div>
                    </div>
                    <div className="col s12 m3 l3 mt-3">
                        <div className="top100Cats">
                            <p><a href="/find/categories/Dance-Studio"><i className="material-icons left">chevron_right</i> Dance Studio</a></p>
                            <p><a href="/find/categories/Debt-Collection-Service"><i className="material-icons left">chevron_right</i> Debt Collection Service</a></p>
                            <p><a href="/find/categories/Delicatessens"><i className="material-icons left">chevron_right</i> Delicatessens</a></p>
                            <p><a href="/find/categories/Dentist"><i className="material-icons left">chevron_right</i> Dentist</a></p>
                            <p><a href="/find/categories/Disability-Services"><i className="material-icons left">chevron_right</i> Disability Services</a></p>
                            <p><a href="/find/categories/DJ-mobile"><i className="material-icons left">chevron_right</i> DJ mobile</a></p>
                            <div id="vm006" style={{ display: 'none'}}>
                                <p><a href="/find/categories/Doctors-General-Practice"><i className="material-icons left">chevron_right</i> Doctors - General Practice</a></p>
                                <p><a href="/find/categories/Dog-Cat-Grooming"><i className="material-icons left">chevron_right</i> Dog & Cat Grooming</a></p>
                                <p><a href="/find/categories/Electrical-Appliances"><i className="material-icons left">chevron_right</i> Electrical Appliances</a></p>
                                <p><a href="/find/categories/Electrical-Appliances-Repairs"><i className="material-icons left">chevron_right</i> Electrical Appliances Repairs</a></p>
                                <p><a href="/find/categories/Electricians"><i className="material-icons left">chevron_right</i> Electricians</a></p>
                                <p><a href="/find/categories/Embroidery-Services"><i className="material-icons left">chevron_right</i> Embroidery Services</a></p>
                            </div>
                            <a href="#"  className="vmction vm006" onClick={e => seemorehandler(e, 'vm006')}
                               data-target="vm002">View more</a>
                        </div>
                    </div>
                    <div className="col s12 m3 l3 mt-3">
                        <div className="top100Cats">
                            <p><a href="/find/categories/Emergency-Services"><i className="material-icons left">chevron_right</i> Emergency Services</a></p>
                            <p><a href="/find/categories/Employment-Services"><i className="material-icons left">chevron_right</i> Employment Services</a></p>
                            <p><a href="/find/categories/Entertainment-Promoters"><i className="material-icons left">chevron_right</i> Entertainment Promoters</a></p>
                            <p><a href="/find/categories/Event-Management"><i className="material-icons left">chevron_right</i> Event Management</a></p>
                            <p><a href="/find/categories/Gas-Appliances-Equipment"><i className="material-icons left">chevron_right</i> Gas Appliances & Equipment</a></p>
                            <p><a href="/find/categories/Glass-Window-Repairs-Home"><i className="material-icons left">chevron_right</i> Glass Window Repairs - Home</a></p>
                            <div id="vm007" style={{ display: 'none'}}>
                                <p><a href="/find/categories/Glass-Window-Repairs-Windscreens"><i className="material-icons left">chevron_right</i> Glass Window Repairs - Windscreens</a></p>
                                <p><a href="/find/categories/Go-Kart-Tracks"><i className="material-icons left">chevron_right</i>  Go Kart Tracks</a></p>
                                <p><a href="/find/categories/Graphic-Designers"><i className="material-icons left">chevron_right</i> Graphic Designers</a></p>
                                <p><a href="/find/categories/Gutter-Cleaning"><i className="material-icons left">chevron_right</i> Gutter Cleaning</a></p>
                                <p><a href="/find/categories/Gymnastic-Clubs"><i className="material-icons left">chevron_right</i> Gymnastic Clubs</a></p>
                                <p><a href="/find/categories/Granny-Flats"><i className="material-icons left">chevron_right</i> Granny Flats</a></p>
                            </div>
                            <a href="#"  className="vmction vm007" onClick={e => seemorehandler(e, 'vm007')}
                               data-target="vm002">View more</a>
                        </div>
                    </div>
                    <div className="col s12 m3 l3 mt-3">
                        <div className="top100Cats">
                            <p><a href="/find/categories/Health-Fitness-Centres"><i className="material-icons left">chevron_right</i> Health & Fitness Centres</a></p>
                            <p><a href="/find/categories/Health-Foods-Products"><i className="material-icons left">chevron_right</i> Health Foods & Products</a></p>
                            <p><a href="/find/categories/Health-Insurance"><i className="material-icons left">chevron_right</i> Health Insurance</a></p>
                            <p><a href="/find/categories/Home-Health-Care-Aids-Equipment"><i className="material-icons left">chevron_right</i> Home Health Care Aids & Equipment</a></p>
                            <p><a href="/find/categories/Hospitals-Private"><i className="material-icons left">chevron_right</i> Hospitals - Private</a></p>
                            <p><a href="/find/categories/Interior-Decorators"><i className="material-icons left">chevron_right</i> Interior Decorators</a></p>
                            <div id="vm008" style={{ display: 'none'}}>
                                <p><a href="/find/categories/Jumping-Castles"><i className="material-icons left">chevron_right</i> Jumping Castles</a></p>
                                <p><a href="/find/categories/Kitchen-Renovations"><i className="material-icons left">chevron_right</i> Kitchen Renovations</a></p>
                                <p><a href="/find/categories/Landscape-Contractors-Designers"><i className="material-icons left">chevron_right</i> Landscape Contractors & Designers</a></p>
                                <p><a href="/find/categories/Landscape-Supplies"><i className="material-icons left">chevron_right</i>  Landscape Supplies</a></p>
                                <p><a href="/find/categories/Lawn-Mowers-Retail-Repairs"><i className="material-icons left">chevron_right</i> Lawn Mowers Retail & Repairs</a></p>
                                <p><a href="/find/categories/Lawn-Turf-Supplies"><i className="material-icons left">chevron_right</i> Lawn & Turf Supplies</a></p>
                            </div>
                            <a href="#"  className="vmction vm008" onClick={e => seemorehandler(e, 'vm008')}
                               data-target="vm002">View more</a>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    );


}


export default HomePage;

