import $ from 'jquery';
import jQuery from 'jquery-ui-bundle/jquery-ui.min'


let stepCount = 1;

$(document).ready(function () {


    $("#subscribeBox").prop('checked' , true);

    $("#step-two-action-back").click(function () {

        $("#step-two").fadeOut(100 , 'linear', function() {
            $("#step-two").addClass("hide");

        });

        $("#step-one").fadeIn(100 , 'linear', function() {
            $("#step-one").removeClass("hide");

        });



    });


    $("#step-three-action-back").click(function () {

        $("#step-three").fadeOut(100 , 'linear', function() {
            $("#step-three").addClass("hide");

        });

        $("#step-two").fadeIn(100 , 'linear', function() {
            $("#step-two").removeClass("hide");

        });



    });

    $("#step-four-action-back").click(function () {

        $("#step-four").fadeOut(100 , 'linear', function() {
            $("#step-four").addClass("hide");

        });

        $("#step-three").fadeIn(100 , 'linear', function() {
            $("#step-three").removeClass("hide");

        });



    });
    
});