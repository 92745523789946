import React, {useEffect, useState} from  'react';
import  '../css/page-users.css';
import Breadcrumbs from '../templates/BreadCrumbs';
import ProfileNavs from '../templates/ProfileNavs';
import {Link, useNavigate} from 'react-router-dom';
import UserSession from '../../../utils/UserSession';
import avatar from '../../../images/avatar-15.png';
import axios from "axios/index";
import Config from "../../../Config";
import useSession, { UseSessionProvider } from 'react-session-hook';

function MyListings(props) {
    const session = useSession();
    const navigate = useNavigate();
    const [listings , setlistings ] = useState([]);
    const [accountType , setAccountType ] = useState([]);
    const [available_listing, setavailable_listing] = useState("");
    const [listingspace, setListingSpace] = useState(UserSession.getListing_space());

    useEffect(()=>{

        if(!UserSession.getToken()){
            navigate('/login', {replace: true})
        }else if(UserSession.getUserData().type === 1){
            navigate('/user/profile', {replace: true})
        }else if(UserSession.getUserProfile().length <= 0){
            navigate('/user/profile/edit', {replace: true});
        }

        window.$(document).ready(function(){
            window.$('tabs').tabs();
        });

        const config = {
            headers: { Authorization: `Bearer ${UserSession.getToken()}` }
        };
        axios.get(Config.links.directories, config)
            .then(function (response) {
                console.log(response);
                setlistings(response.data.directories);
                setAccountType(response.data.account_type);
                setavailable_listing(response.data.available_listing);


            })
            .catch(function (error) {
               // console.log(error);
            });




    },[]);

    const breacrumbsitems = [
        {name : 'home', link : '/'},
        {name : 'profile', link : '/user/profile'},
        {name : 'my listings', link : '#'},
    ];

    const title = "My Listings";

    function deleteListing(id) {
        window.$('#savingLoadingScreen').show();
        const config = { headers: { Authorization: `Bearer ${UserSession.getToken()}` }};
        axios.delete(Config.links.directories+"/"+id, config)
            .then(function (response) {
                //console.log(response);
                window.$('#savingLoadingScreen').hide();
                setlistings(response.data.directories);
                setAccountType(response.data.account_type);
                setavailable_listing(response.data.available_listing);
                UserSession.setlisting_space(response.data.listing_space);
                setListingSpace(response.data.listing_space);
            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
            });
    }


    return(
        <div id="profilepage" style={{ paddingTop: 10, paddingBottom: 50}}>
            <div className="container">
                <div className="row">
                    <div className="col s12 m9 l9 height-100vh no-padding">

                        <div className="row">
                            <div className="col s12 m8 l7">
                                <Breadcrumbs items={breacrumbsitems} title={title}/>
                            </div>

                        </div>



                        <div className="padding-1">
                            <table className="responsive-table">
                                <thead>
                                <tr>
                                    <th data-field="id">Name</th>
                                    <th data-field="name">Email</th>
                                    <th data-field="price">Contact No.</th>
                                    <th data-field="total" colSpan={1} className="center">Actions</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    listings.length > 0 ?
                                        Object.keys(listings).map((key, i) => (
                                            <tr key={key}>
                                                <td>{listings[i].name}</td>
                                                <td>{listings[i].email}</td>
                                                <td>{listings[i].contact_no}</td>
                                                <td className="center">
                                                    <Link to={"/user/listings/"+listings[i].id } className="btn btn-flat black waves-effect white-text btn-small">View</Link>
                                                    &nbsp;<Link  to={"/user/listings/edit/"+listings[i].id } className="btn btn-flat green waves-effect white-text btn-small">Edit</Link>
                                                    &nbsp;<button onClick={() => deleteListing(listings[i].id)} className="deleteBtn btn btn-flat red waves-effect white-text btn-small">Delete</button></td>
                                            </tr>
                                        ))
                                    :
                                    <tr>
                                        <td colSpan={6} className="">No listing found.</td>
                                    </tr>
                                }





                                </tbody>
                            </table>
                        </div>

                        <div className="row padding-1">
                            <div className="col s12 m6 l6  lighten-2">
                                <p ><strong className="black-text">Subscription Plan:</strong> {accountType.name}</p>
                                <p><strong className="black-text">Available Listing:</strong> {available_listing}</p>
                            </div>
                            <div className="col s12 m6 l6 right-align-lg right-align-md center-align-sm">

                                {
                                    listingspace < 1 ? "" :
                                        <Link to="/user/listings/add"  style={{ margin: 10}} className="btn btn-large green waves-effect"><i className="material-icons left">add</i>Add Listing</Link>


                                }

                            </div>
                        </div>

                    </div>
                    <div className="col s12 m4 l3 " >
                        {
                            UserSession.getToken() ?
                                <ProfileNavs class={'my-listing'}/> : ""
                        }
                    </div>

                </div>
            </div>
        </div>
    )

}

export default MyListings;