import react,{useEffect, useState} from 'react';
import {Link} from "react-router-dom";

export default function Pagination(props) {

    return(
        <ul className="pagination" style={props.style}>

            {
                props.currentPage > 1 ?
                    <li key={-1}  className=""><Link to={"/categories/"+(props.currentPage -1)}><i className="material-icons dp48">keyboard_arrow_left</i></Link></li>
                    :
                    <li className="disabled" style={{ cursor: 'pointer'}}><i className="material-icons dp48">keyboard_arrow_left</i></li>

            }

            {
                Array(props.lastPage).fill(1).map((el, i) =>
                <li key={i+1} onClick={ (e) => props.onClick(e)} className={(props.currentPage == (i+1) ? 'active' : '')}><Link to={"/categories/"+(i+1)}>{i+1}</Link></li>
                )
            }

            {
                props.currentPage >= props.lastPage ?
                    <li className="disabled" style={{ cursor: 'pointer'}}><i className="material-icons dp48">keyboard_arrow_right</i></li>
                    :
                    <li ><Link to={"/categories/"+(props.currentPage +1)}><i className="material-icons dp48">keyboard_arrow_right</i></Link></li>
            }

        </ul>
    );


}