import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from "react-router-dom";


import Header from './components/frontend/templates/Header.Component';
import HomePage from './components/frontend/pages/Home.Component';
import AddBusinessPage from './components/frontend/pages/AddBusiness.Componentfuntion';
import FooterComponent from './components/frontend/templates/Footer.Component';
import BusinessSearchResult from './components/frontend/pages/BusinessSearchResult.Component';
import SuggestABusiness from './components/frontend/pages/SuggestABusiness.Component';
import Categories from './components/frontend/pages/Categories.Component';
import SearchResult from './components/frontend/pages/SearchResult.Component';
import Page404 from './components/404.Component';
import RegisterPage from './components/auth/Register.Component';
import LoginPage from './components/auth/login.Component';
import ForgotPassword from './components/auth/ForgotPassword.Componnent';
import ResetPassword from './components/auth/ResetPassword.Componnent';
import ProfilePage from './components/backend/pages/Profile.Component';
import EditProfile from './components/backend/pages/EditProfile.Component';
import EditPasswordPage from './components/backend/pages/EditPassword.Component';
import MyListings from './components/backend/pages/MyListings.Component';
import Listing from './components/backend/pages/Listing.Component';
import EditListing from './components/backend/pages/editListing.Component';
import AddListing from './components/backend/pages/AddListing.Component';
import Listingpage from './components/frontend/pages/Listingpage';
import UnverifiedPage from './components/auth/UnVerified.Component';
import UpgradePage from './components/backend/business/Upgrade.Component';
import BusinessRegister from './components/backend/business/BusinessRegistration.Component';
import TermsAndConditions from './components/frontend/pages/TermsAndConditions';
import TermsOfUse from './components/frontend/pages/Termsofuse';
import EmailVerification from './components/frontend/pages/EmailConfirmation';
import ContactPage from './components/frontend/pages/contactpage';
import CategoryResults from './components/frontend/pages/CategoryResult.Component';
import Config from "./Config";

import ReactGA from 'react-ga';
ReactGA.initialize(Config.links.GA_TRACKING_ID);


function App() {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    } , [window.location.pathname + window.location.search]);


    return (
        <BrowserRouter>


            <Header/>



            <Routes>
                <Route exact path="*" element={<Page404/>} />
                <Route exact path="/" element={<HomePage/>} />
                <Route  path="/add-business" element={<AddBusinessPage/>} />
                <Route  path="/add-business/find/:location/:name" element={<BusinessSearchResult/>} />
                <Route  path="/suggest-a-business"  element={<SuggestABusiness/>} />
                <Route  path="/suggest-a-business/:location/:name"  element={<SuggestABusiness/>} />
                <Route  path="/categories/:id"  element={<Categories/>} />
                <Route  path="/categories"  element={<Categories/>} />
                <Route  path="/find/:c_name"  element={<SearchResult/>} />
                <Route  path="/find/categories/:cat_name"  element={<CategoryResults/>} />
                <Route  path="/find/"  element={<SearchResult/>} />
                <Route  path="/user/verified/"  element={<EmailVerification/>} />

                <Route  path="/find/:c_location/:c_name"  element={<SearchResult/>} />

                <Route  path="/:cat_name/:slocation/:sname/:listingId"  element={<Listingpage/>} />



                <Route  path="/terms-of-use"  element={<TermsOfUse/>} />
                <Route  path="/terms-and-conditions"  element={<TermsAndConditions/>} />
                <Route  path="/register/business"  element={<BusinessRegister/>} />
                <Route  path="/user/upgrade"  element={<UpgradePage/>} />
                <Route  path="/user/verify"  element={<UnverifiedPage/>} />
                <Route  path="/contact-us"  element={<ContactPage/>} />
                <Route  path="/register"  element={<BusinessRegister/>} />
                <Route  path="/login"  element={<LoginPage/>} />
                <Route  path="/user/profile"  element={<ProfilePage/>} />
                <Route  path="/user/profile/edit"  element={<EditProfile/>} />
                <Route  path="/user/profile/update-password"  element={<EditPasswordPage/>} />
                <Route  path="/user/listings"  element={<MyListings/>} />
                <Route  path="/user/listings/:listingId"  element={<Listing/>} />
                <Route  path="/user/listings/edit/:listingId"  element={<EditListing/>} />
                <Route  path="/user/listings/add"  element={<AddListing/>} />
                <Route  path="/user/forgot-password"  element={<ForgotPassword/>} />
                <Route  path="password/reset/:token"  element={<ResetPassword/>} />
            </Routes>

            <FooterComponent/>

        </BrowserRouter>

    )
}

ReactDOM.render(<App />, document.querySelector('#root'));