import React,{useEffect, useState} from  'react';
import  '../css/page-users.css';
import Breadcrumbs from '../templates/BreadCrumbs';
import ProfileNavs from '../templates/ProfileNavs';
import {useNavigate} from 'react-router-dom';
import UserSession from '../../../utils/UserSession';
import axios from "axios/index";
import Config from '../../../Config';
import useSession, { UseSessionProvider } from 'react-session-hook';
import $ from "jquery";

function EditPasswordPage(props) {

    const navigate = useNavigate();
    const session = useSession();
    let username = "";
    let usernameLenght = [];

    const [current_password, set_current_password] = useState("");
    const [password , set_password] = useState("");
    const [password_confirmation, set_password_confirmation] = useState("");
    const [errors, setErrors] = useState([]);

    useEffect(()=>{


        window.$("#updatePassForm").validate({
            rules: {
                password: {
                    required: true,
                    minlength: 8
                },
                password_confirmation: {
                    required: true,
                    minlength: 8,
                    equalTo: "#password"
                },

            },
            messages: {
                password_confirmation:{
                    equalTo: "Your passwords don't match!",
                    minlength: "Enter at least 8 characters"
                },
            },

            errorElement : 'div',
            errorPlacement: function(error, element) {
                var placement = $(element).data('error red-text');
                if (placement) {
                    $(placement).append(error)
                } else {
                    error.insertAfter(element);
                }
            }
        });


        if(!UserSession.getToken()){
            navigate('/login', {replace: true})
        }else {
             username = UserSession.getUserData().name;
             usernameLenght = username.split(" ");
        }

        window.$(document).ready(function(){
            window.$('.datepicker').datepicker();
        });

        window.$('.phone-format').formatter({
            'pattern': '({{99}}) {{9999}}-{{9999}}',
            'persistent': true
        });

    }, []);

    const handleSubmit = (e)=>{
        e.preventDefault();
        const config = {
            headers: { Authorization: `Bearer ${UserSession.getToken()}` }
        };
        axios.post(Config.links.updatePassword, {
            current_password : current_password,
            password : password,
            password_confirmation : password_confirmation
        },config)
            .then(function (response) {
                //console.log(response);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('SAVE CHANGES');

                if(response.data.message === 'failed'){
                    setErrors(response.data.error);
                }else if(response.data.message === 'ok'){
                    window.$('#resultTxt').text("Update Successfully.");
                }else {
                    window.$('#resultTxt').text(response.data.message);
                }



            })
            .catch(function (error) {
                //console.log(error);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('SAVE CHANGES');
                setErrors(error.data.error);
            });
    };

    const handleChange = (e) =>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case "current_password":
                set_current_password(value);
                break;
            case "password":
                set_password(value);
                break;
            case "password_confirmation":
                set_password_confirmation(value);
                break;
        }
    };





    const breacrumbsitems = [
        {name : 'home', link : '/'},
        {name : 'profile', link : '/user/profile'},
        {name : 'update password', link : '#'},
    ];

    const title = "Update Password";

    return(
        <div id="profilepage" style={{ paddingTop: 0, paddingBottom: 50}}>
            <div className="container">
                <div className="row">

                    <div className="col s12 m9 l9  no-padding">
                        <Breadcrumbs items={breacrumbsitems} title={title}/>

                        <form id="updatePassForm" onSubmit={e => handleSubmit(e)} style={{ padding: 8, marginTop: 20}}>

                            <div className="row">
                                <div className="col s12 m12 l12">
                                    <strong>Your current password<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input onChange={e => handleChange(e)} id="current-password" required type="password" name="current_password" placeholder=""/>
                                    </div>
                                </div>


                            </div>

                            <div className="row" style={{ marginTop: 10}}>
                                <div className="col s12 m6 l6">
                                    <strong>Your new password<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input onChange={e => handleChange(e)} required type="password" id="password" name="password" placeholder=""/>
                                    </div>
                                </div>

                                <div className="col s12 m6 l6">
                                    <strong>Confirm password<span className="red-text">*</span></strong>
                                    <div className="custom-input no-icon">
                                        <input onChange={e => handleChange(e)} required type="password"  id="password_confirmation" name="password_confirmation" />
                                    </div>
                                </div>
                            </div>


                            <div className="row" style={{ marginTop: 10}}>
                                <div className="col s12 m12 l12">
                                    {Object.keys(errors).map((key, i) => (

                                        Object.keys(errors[key]).map((key2, j) => (
                                            <p key={i} className="red-text" style={{ marginTop: 0, marginBottom: 0}}>{errors[key][key2]}<br/></p>
                                        ))
                                    ))}
                                    <p id="resultTxt"></p>
                                    <button id='submitBtn' className="btn btn-large green">Save changes</button>
                                </div>
                            </div>


                        </form>

                    </div>

                    <div className="col s12 m4 l3 " >
                        {
                            UserSession.getToken() ?
                                <ProfileNavs class={'update-password'}/> : ""
                        }
                    </div>

                </div>
            </div>
        </div>
    )

}

export default EditPasswordPage;