import { SocialIcon } from 'react-social-icons';

export default function Socials(props) {

    return(
        <ul className="social-icon-footer-list">
            <li ><SocialIcon className="social-icon-footer" url={"#"} network="facebook" /></li>
            <li ><SocialIcon className="social-icon-footer" url={"#"} network="twitter" /></li>
            <li ><SocialIcon className="social-icon-footer" url={"#"} network="instagram" /></li>
            <li ><SocialIcon className="social-icon-footer" url={"#"} network="google" /></li>
            <li ><SocialIcon className="social-icon-footer" url={"#"} network="linkedin" /></li>
        </ul>
    )
}