import React, {useEffect, useState} from 'react';
import '../css/page-users.css';
import Breadcrumbs from '../templates/BreadCrumbs';
import ProfileNavs from '../templates/ProfileNavs';
import {useNavigate} from 'react-router-dom';
import UserSession from '../../../utils/UserSession';
import avatar from '../../../images/avatar-15.png';
import Config from "../../../Config";
import TempLogo from "../../../images/usericon.png";
import axios from "axios/index";
import useSession, { UseSessionProvider } from 'react-session-hook';

function ProfilePage(props) {

    const navigate = useNavigate();

    const session = useSession();
    const [first_name, set_first_name] = useState("");
    const [last_name, set_last_name] = useState('');
    const [phone_no, set_phone_no] = useState('');
    const [dob, set_dob] = useState('');
    const [address, set_address] = useState('');
    const [state, set_state] = useState('');
    const [suburb, set_suburb] = useState('');
    const [postcode, set_postcode] = useState('');
    const [profile, set_profile] = useState(null);
    const [NotifActivated, setNotifActivated] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const  activated = queryParams.get("activated");

    useEffect(() => {

        if(!UserSession.getToken()){
            navigate('/login', {replace: true});
        }else {

            if(UserSession.getUserData().email_verified_at == null){
                navigate('/user/verify', {replace: true})
            }else if(UserSession.getUserProfile().length > 0){

                set_first_name(JSON.parse(UserSession.getUserProfile()[0].full_name).first_name);
                set_last_name(JSON.parse(UserSession.getUserProfile()[0].full_name).last_name);
                set_phone_no(UserSession.getUserProfile()[0].contact_number);
                set_dob(UserSession.getUserProfile()[0].dob);
                set_address(UserSession.getUserProfile()[0].address);
                set_state(UserSession.getUserProfile()[0].state);
                set_suburb(UserSession.getUserProfile()[0].suburb);
                set_postcode(UserSession.getUserProfile()[0].postcode);
                set_profile(UserSession.getUserProfile()[0].profile);
            }else {
                navigate('/user/profile/edit', {replace: true});
            }

            window.$('.logoutBtn').click(function () {
                window.$('#logoutLoadingScreen').show();

                axios.post(Config.links.user_logout, {
                    user_id: UserSession.getUserData().id,
                })
                    .then(function (response) {
                        window.$('#logoutLoadingScreen').hide();
                        console.log(response);
                        UserSession.clear();
                        navigate('/login', { replace: false });

                    })
                    .catch(function (error) {
                        console.log(error);
                        window.$('#logoutLoadingScreen').hide();
                    });



            });


            if(UserSession.getUserData().email_verified_at != null){

                /*let at = new Date(UserSession.getUserData().email_verified_at);
                let now = new Date();
                let diff = now.getTime() - at.getTime() ;
                let total = diff / 1000 / 60;*/
                if(activated === "true"){
                    setNotifActivated(true);
                }
            }






        }

    }, []);





    const breacrumbsitems = [
        {name: 'home', link: '/'},
        {name: 'profile', link: '/user/profile'},
    ];

    const title = "Profile";

    return (
        <div id="profilepage" style={{paddingTop: 0, paddingBottom: 50}}>
            <div className="container ">
                <div className="row " >


                        {
                            NotifActivated ?
                                <div className="col s12 mt-1">
                                    <div className="card-alert  green">
                                        <div className="card-content white-text">
                                            <p>CONGRATULATIONS : Your account has been activated by verifying your email address.</p>
                                        </div>
                                        <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close">
                                        </button>
                                    </div>
                                </div>
                                : ""
                        }




                    <div className="col s12 m9 l9 ">
                        <Breadcrumbs items={breacrumbsitems} title={title}/>



                        <div className="row " style={{ marginTop: 20}}>

                            <div className="col s12 m6 l6 card-width">
                                <div className="row " style={{ marginTop: 0}}>
                                    <div className="col s12 m3 l4 center-align">
                                        <div className="avatar-holder" style={{ margin: 'auto'}}>
                                            <img className=" circle " width="120" style={{ width: 120, height : 120 , objectFit : 'cover'}} src={profile != null ? Config.links.asset_link+profile : TempLogo} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col s12 m9 l8 left-align-lg center-align-sm">
                                        <h5 style={{margin: 0, marginTop: 5, textTransform: 'capitalize'}}>{first_name+" "+last_name}</h5>
                                        <p style={{marginTop: 5, marginBottom: 5}}>{UserSession.getUserData().email}</p>
                                        <button className="btn logoutBtn  white red-text"><i className="material-icons left">exit_to_app</i>Log Out</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row " style={{ marginTop: 20}}>
                            <div className="col s12 center-align-sm left-align-md left-align-lg">
                                <div className="black-text"><strong>Contact Number</strong></div>
                                <div>

                                    <p style={{ display: 'inline-block'}}>
                                        <i className="material-icons left">phone</i>
                                        {phone_no != null && phone_no != 'null' ? phone_no : '----'}
                                    </p>
                                </div>

                            </div>
                        </div>







                    </div>
                    <div className="col s12 m4 l3 ">

                        {
                            UserSession.getToken() ?
                                <ProfileNavs class={'profile'}/> : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProfilePage;