import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Config from "../../../Config";
import UserSession from "../../../utils/UserSession";
import axios from "axios";

export default function EmailVerification(props) {

    const navigate = useNavigate();
    useEffect(()=>{

        //alert(verified);

        if(!UserSession.getToken()){
            navigate('/user/login', {replace: true})
        }else {


            if(UserSession.getUserData().email_verified_at != null){
                navigate('/user/profile?activated=true', {replace: true})
            }else {

                const formData = new FormData();
                formData.append('userID' , UserSession.getUserData().id);
                const config = {
                    headers: { Authorization: `Bearer ${UserSession.getToken()}` }
                };
                axios.post(Config.links.checkIfEmailVerified, formData,config)
                    .then(function (response) {
                        console.log(response);
                        if(response.data.message === 'ok'){
                            let userData = UserSession.getUserData();
                            userData.email_verified_at = response.data.email_verified_at;
                            UserSession.setUserData(userData);
                            navigate('/user/profile?activated=true', {replace: true})
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });




            }

        }
    }, []);

    return (
        <div className="container pt-10 pb-10">

            <div className="row">
                <div className="col s12 center">
                    <h1>Congratulations!</h1>
                    <p>Your account has been verified.</p>
                    <a href="/user/profile" className="btn btn-large green">Click here to get started</a>
                </div>
            </div>

        </div>
    )

}